import React from 'react';
import Bridge from '../../components/Bridge/Bridge';
import styles from './Bridge.module.css';
// import { PINK_PRIMARY } from '../../constants';

export default function BridgePage() {
    const isMobile =
        window.innerWidth < 624 ||
        navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i);

    return (
        <div className={styles.bridge_page_container}>
            <Bridge />
        </div>
    );
}
