import { ReactNode } from 'react';
import styles from './ContentContainer.module.css';

interface ContentContainerPropsIF {
    children: ReactNode;
    customWidth?: boolean;
    customWidthAuto?: boolean;
    isOnTradeRoute?: boolean;
    noPadding?: boolean;
    noStyle?: boolean;
}

export default function ContentContainer(props: ContentContainerPropsIF) {
    const {
        children,
        isOnTradeRoute,
        // customWidth,
        // customWidthAuto,
        noPadding,
        noStyle,
    } = props;

    // const customWidthStyle = customWidth ? styles.customWidth_container : null;
    const tradeRouteStyle = isOnTradeRoute
        ? styles.no_background
        : styles.swap_bg;
    // const swapRouteStyle = isOnTradeRoute ? null : styles.swap_route;
    // const customWidthAutoStyle = customWidthAuto
    //     ? styles.customWidthAuto
    //     : styles.container;

    if (noStyle) return <>{children}</>;

    // ${swapRouteStyle}`
    return (
        <section
            // className={`$ ${customWidthStyle} ${customWidthAutoStyle} ${tradeRouteStyle}`}
            style={{
                borderTop: '12px solid #E696AC',
                borderLeft: '12px solid #ce6C87',
                borderBottom: '12px solid #E696AC',
                borderRight: '12px solid #ce6C87',
                borderRadius: 48,
            }}
        >
            <section className={`${styles.window} ${tradeRouteStyle}`}>
                <div
                    className={`${styles.main_content} ${
                        !isOnTradeRoute && styles.swap_main_content
                    }`}
                    style={{ padding: noPadding ? '' : '8px 16px 16px 16px' }}
                >
                    {children}
                </div>
            </section>
        </section>
    );
}
