import { ChainId } from './chainId';

export const NATIVE_ADDRESS = '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE';

export const NO_ADDRESS = '0xAD7039512f3027Df226a3D2c20945E9FbEAf6960';
export const YES_ADDRESS = '0x20fE91f17ec9080E3caC2d688b4EcB48C5aC3a9C';
export const MAYBE_ADDRESS = '0x2be6efB916047a2b633d0C43b4345E6cE696DD4E';
export const SMORE_ADDRESS = '0x4A51DD49969dF5645428c2b86E75094795Bb17A4'; // '0x4Fc3e9A838ebf4FFA998F6EA616D37fA1630fA56'; // '0x535c36F0059EB31D51a8304345F38A401F9d88Fe';
export const MORE_ADDRESS = '';
export const EXIT_ADDRESS = '0x124c8eBBbc7ce51Cede724dD35BdDb010A5f4162';

export const CROC_DEX_ADDRESS = {
    [ChainId.ETHEREUM]: '0xAaAaAAAaA24eEeb8d57D431224f73832bC34f688',
    [ChainId.BLAST]: '0xaAaaaAAAFfe404EE9433EEf0094b6382D81fb958',
};

export const CROC_ROUTER_ADDRESS = {
    [ChainId.ETHEREUM]: '0x533E164ded63f4c55E83E1f409BDf2BaC5278035',
    [ChainId.BLAST]: '0xaab17419F062bB28CdBE82f9FC05E7C47C3F6194',
};

export const USDB_ADDRESS = {
    [ChainId.BLAST]: '0x4300000000000000000000000000000000000003', // USDB
};

export const ETH_ADDRESS = '0x0000000000000000000000000000000000000000';

export const WNATIVE_ADDRESS = {
    [ChainId.FANTOM]: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83',
    [ChainId.BLAST]: '0x4300000000000000000000000000000000000004', // WETH
    [ChainId.MANTLE]: '0x78c1b0C915c4FAA5FffA6CAbf0219DA63d7f4cb8',
    [ChainId.BASE]: '0x4200000000000000000000000000000000000006',
    [ChainId.ARBITRUM]: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
    [ChainId.AVALANCHE]: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
};
