/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable quotes */
import React, { memo, useContext, useState } from 'react';
// import styled from 'styled-components/macro';
import {
    BLACK,
    ChainId,
    WNATIVE_ADDRESS,
    TAN,
    // YELLOW,
    PURPLE,
    BLUE,
    RED,
    RPC,
    WHITE,
    NATIVE_ADDRESS,
    PINK_SECONDARY,
} from '../../constants/index';
import { UserDataContext } from '../../contexts/UserDataContext';
// import { FlexContainer } from '../../styled/Common';
// import styles from './Bridge.module.css';
// import styles from '../../components/Home/Landing/BackgroundImages.module.css';
import BigNumber from 'bignumber.js';
import { RouteData, Squid } from '@0xsquid/sdk';
import { getSigner } from '../../utils';
// import {
//     ScrollableContainer,
//     ShadowBox,
// } from '../../styled/Components/Analytics';
import { ethers } from 'ethers';
// import { JsonRpcSigner, Web3Provider } from '@ethersproject/providers';
import { CrocEnvContext } from '../../contexts/CrocEnvContext';
import { FlexContainer } from '../../styled/Common';

function Bridge() {
    // const { library } = useActiveWeb3React()
    const {
        // crocEnv,
        chainData: { chainId },
    } = useContext(CrocEnvContext);
    const { isUserConnected, userAddress } = useContext(UserDataContext);
    // const library = new ethers.providers.JsonRpcProvider(
    //     //   @ts-ignore
    //     RPC[chainId].toString(),
    // );
    const account =
        userAddress?.toString() ?? '0xFd63Bf84471Bc55DD9A83fdFA293CCBD27e1F4C8';
    const library = userAddress
        ? // @ts-ignore
          new ethers.providers.Web3Provider(window.ethereum)
        : new ethers.providers.JsonRpcProvider('https://rpc.blast.io/');
    // const signer = library.getSigner(account).connectUnchecked();
    // @ts-ignore
    const signer = getSigner(library, account); // .connectUnchecked();

    // amounts //
    const [inputAmount, setInputAmount] = useState('');
    const [outputAmount, setOutputAmount] = useState('');
    //   const [fromAmount, setFromAmount] = useState('');

    const [route, setRoute] = useState<RouteData>();

    // squid details //
    const baseUrl = 'https://api.0xsquid.com';
    const integratorId =
        process.env.NEXT_PUBLIC_SQUID_INTEGRATOR_ID || 'example-swap-widget';

    // GETS: ROUTE //
    const getRoute = async () => {
        // _fromAmount
        // [√] SQUID ROUTE //

        // initializes: SDK
        const squid = new Squid({
            // TODO: update to v2 upon integratorId.
            baseUrl: baseUrl,
            integratorId: integratorId,
        });

        await squid.init();
        console.log('squid initialized');
        console.log('inputAmount: %s', inputAmount);

        // todo: update params to align with desired assests to swap.
        const params = {
            fromAddress: isUserConnected
                ? userAddress
                : '0xFd63Bf84471Bc55DD9A83fdFA293CCBD27e1F4C8', // signer.address,
            toAddress: isUserConnected
                ? userAddress
                : '0xFd63Bf84471Bc55DD9A83fdFA293CCBD27e1F4C8', // signer.address,
            fromChain: ChainId.AVALANCHE.toString(),
            fromToken: NATIVE_ADDRESS,
            fromAmount: (Number(inputAmount) * 1e18).toString(), // fromAmountWithDecimals(inputAmount ?? '0'), // "10000000000000000000", // fromAmountWithDecimals(_fromAmount ?? '0'), // "10000000",
            toChain: ChainId.BLAST.toString(),
            toToken: NATIVE_ADDRESS,
            slippage: 1,
        };

        //   @ts-ignore
        const { route } = await squid.getRoute(params);
        setRoute(route);
        setOutputAmount(
            (Number(route.estimate.toAmount) / 1e18).toFixed(2).toString(), // +
            // ' ETH',
        );
        console.log(params);
        console.log('route: %s', await route?.estimate.toAmount);
    };

    // HANDLES: SWAP //
    const handleSwap = async () => {
        // _fromAmount

        // [√] SQUID SWAP //

        // initialize: SDK
        const squid = new Squid({
            // TODO: update to v2 upon integratorId.
            baseUrl: baseUrl,
            integratorId: integratorId,
        });

        await squid.init();
        console.log('squid initialized');

        const params = {
            fromAddress: isUserConnected
                ? userAddress
                : '0xFd63Bf84471Bc55DD9A83fdFA293CCBD27e1F4C8', // signer.address,
            toAddress: isUserConnected
                ? userAddress
                : '0xFd63Bf84471Bc55DD9A83fdFA293CCBD27e1F4C8', // signer.address,
            // todo: assumes fromChain is current chain
            fromChain: ChainId.AVALANCHE.toString(),
            fromToken: NATIVE_ADDRESS,
            fromAmount: (Number(inputAmount) * 1e18).toString(), // fromAmountWithDecimals(inputAmount ?? '0'), // "10000000000000000000", // fromAmountWithDecimals(_fromAmount ?? '0'), // "10000000",
            toChain: ChainId.BLAST.toString(),
            toToken: NATIVE_ADDRESS,
            slippage: 1,
        };

        //   @ts-ignore
        const { route } = await squid.getRoute(params);

        console.log('inputAmount: %s', route.estimate.fromAmount);
        console.log('outputAmount: %s', route.estimate.toAmount);

        await setOutputAmount(
            new BigNumber(route.estimate?.toAmount.toString() ?? '1')
                .div(10 ** 18)
                .toString(),
        );

        await setRoute(route);

        const tx = await squid.executeRoute({
            signer,
            route,
        });
        const txReceipt = await tx.wait();

        console.log(txReceipt);
    };

    const isTiny = window.innerWidth < 424;
    const isMobile = window.innerWidth < 768;
    const mobileLR = '4px solid #ce6C87';
    const mobileTB = '4px solid #E696AC';

    return (
        <FlexContainer
            // fullHeight
            // fullWidth
            style={{
                display: 'flex',
                // flexGrow: 1,
                flexDirection: 'column',
                // width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                alignContent: 'center',
                marginTop: isMobile ? '4rem' : '6rem',
                borderTop: isMobile ? mobileTB : '12px solid #E696AC',
                borderLeft: isMobile ? mobileLR : '12px solid #ce6C87',
                borderBottom: isMobile ? mobileTB : '12px solid #E696AC',
                borderRight: isMobile ? mobileLR : '12px solid #ce6C87',
                borderRadius: 48,
                padding: isMobile ? 18 : 24,
                backgroundColor: 'rgb(246, 177, 195)',
            }}
        >
            <head>
                <title>{`Bridge Crosschain`}</title>
                <meta name='description' content='Bridge Crosschain' />
                <link rel='icon' href='/bannerImage.png' />
            </head>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: BLACK,
                    color: WHITE,
                    fontSize: '1.2rem',
                    fontStyle: 'bold',
                    border: '1px solid',
                    width: '100%',
                    padding: 12,
                    borderRadius: 24,
                    // gap: 12,
                }}
            >
                {/* INPUT DATA */}
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: BLACK,
                        color: WHITE,
                        fontSize: '1.2rem',
                        fontStyle: 'bold',
                        // height: 48,
                        width: '100%',
                        gap: 12,
                        marginTop: 36,
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: BLACK,
                            color: BLUE,
                            fontSize: '1.2rem',
                            fontStyle: 'bold',
                            height: 48,
                            width: '100%',
                            marginBottom: 24,
                            border: '2px solid',
                            borderRadius: 12,
                        }}
                    >
                        {'AVAX'}
                    </div>
                    <input
                        value={inputAmount}
                        placeholder='Enter Amount'
                        onChange={(e) => setInputAmount(e.target.value)}
                        style={{
                            display: 'flex',
                            width: '100%',
                            height: 48,
                            border: '2px solid',
                            borderColor: !inputAmount ? RED : BLUE,
                            borderRadius: 12,
                            marginBottom: 24,
                            backgroundColor: BLACK,
                            color: WHITE,
                            fontSize: '1.2rem',
                            paddingLeft: 12,
                            fontStyle: 'bold',
                        }}
                    />
                </div>

                {/* OUTPUT DATA */}
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: BLACK,
                        color: WHITE,
                        fontSize: '1.2rem',
                        fontStyle: 'bold',
                        // height: 48,
                        width: '100%',
                        gap: 12,
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: BLACK,
                            color: BLUE,
                            fontSize: '1.2rem',
                            fontStyle: 'bold',
                            height: 48,
                            width: '100%',
                            border: '2px solid',
                            borderRadius: 12,
                        }}
                    >
                        {'ETH'}
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: BLACK,
                            borderColor: BLUE,
                            color: BLUE,
                            fontSize: '1.2rem',
                            fontStyle: 'bold',
                            height: 48,
                            width: '100%',
                            border: '2px solid',
                            borderRadius: 12,
                        }}
                    >
                        {outputAmount ?? 0}
                    </div>
                </div>

                {inputAmount && (
                    <button
                        onClick={getRoute}
                        style={{
                            display: 'flex',
                            width: '100%',
                            border: '2px solid',
                            borderColor: !inputAmount ? RED : BLUE,
                            borderRadius: 12,
                            marginTop: 72,
                            backgroundColor: !inputAmount ? RED : BLUE,
                            color: BLACK,
                            fontSize: '1.2rem',
                            fontStyle: 'bold',
                            justifyContent: 'center',
                            padding: '1rem',
                            fontWeight: 'bold',
                        }}
                        // disabled={!inputAmount}
                    >
                        {
                            // !inputAmount ? 'Input Amount' :
                            'Calculate Output'
                        }
                    </button>
                )}
                {inputAmount && outputAmount && (
                    <button
                        onClick={handleSwap}
                        style={{
                            display: 'flex',
                            width: '100%',
                            border: '2px solid',
                            borderColor: !isUserConnected ? RED : WHITE,
                            borderRadius: 12,
                            marginTop: 72,
                            backgroundColor: PINK_SECONDARY,
                            color: BLACK,
                            fontSize: '1.2rem',
                            fontStyle: 'bold',
                            justifyContent: 'center',
                            padding: '1rem',
                            fontWeight: 'bold',
                        }}
                    >
                        {userAddress
                            ? `Spend ${inputAmount} AVAX`
                            : 'Wallet Disconnected'}
                    </button>
                )}
            </div>
        </FlexContainer>
    );
}

export default memo(Bridge);
