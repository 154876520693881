import styles from './NotFound.module.css';
import NotFoundLottie from '../../components/NotFound/NotFoundLottie/NotFoundLottie';
import Game from '../../components/NotFound/Game/Game';
import React from 'react';
export default function NotFound() {
    return (
        <div
            className={styles.container}
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                gap: 12,
                // height: '100vh',
            }}
        >
            <Game />
            <br />
            <NotFoundLottie />
        </div>
    );
}
