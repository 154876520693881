import { ChainId } from './chainId';

export const RPC = {
    [ChainId.ETHEREUM]: 'https://api.sushirelay.com/v1',
    [ChainId.BASE]: 'https://rpc.ankr.com/base',
    [ChainId.BLAST]: 'https://rpc.blast.io/',
    //   [ChainId.FANTOM]: 'https://rpcapi.fantom.network',
    [ChainId.FANTOM]: 'https://rpc.ankr.com/fantom',
    //   [ChainId.MATIC]: 'https://polygon-rpc.com/',
    [ChainId.MATIC]: 'https://rpc.ankr.com/polygon',
    //   [ChainId.BSC]: 'https://bsc-dataseed.binance.org/',
    //   [ChainId.AVALANCHE]: 'https://api.avax.network/ext/bc/C/rpc',
    [ChainId.AVALANCHE]: 'https://rpc.ankr.com/avalanche',
};

// export default RPC;
