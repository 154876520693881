export const BLUE = '#005AFF';
export const PINK_PRIMARY = '#ce6C87';
export const PINK_SECONDARY = '#E696AC';
// export const PURPLE = '#640DBE';
export const PURPLE = '#A480FF';
export const TAN = '#15120E';
export const LIGHT_TAN = '#23252b';
// export const TAN = '#2a241c';
// export const TAN = '#C0A480';
// export const BLUE = '#666BE7'
export const YELLOW = '#F4F704';
export const PALE_YELLOW = '#7f7f31';
export const DARK = '#0E193E';
export const WHITE = '#FFFFFF';
export const BLACK = '#000000';
export const RED = '#FF0000';
// export const YELLOW = '#EFFE00';
export const GREEN = '#90EE90';
export const LIGHT_BLUE = '#5AC4F6';
export const DARK_BLUE = '#295FF4';
export const MAYBE_BLUE = '#58C5F2'; // '#78FFF6';
export const ONE_MILLION = '1000000000000000000000000';
export const ONE_HUNDRED_THOUSAND = '100000000000000000000000';

export * from './addresses';
export * from './abis';
export * from './chainId';
export * from './rpc';
