import { TokenIF } from '../types/token/TokenIF';

export const mainnetETH: TokenIF = {
    address: '0x0000000000000000000000000000000000000000',
    chainId: 1,
    decimals: 18,
    fromList: '/ambient-token-list.json',
    logoURI:
        'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png',
    name: 'Ether',
    symbol: 'ETH',
};

export const mainnetUSDC: TokenIF = {
    address: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
    chainId: 1,
    decimals: 6,
    fromList: '/ambient-token-list.json',
    logoURI:
        'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png',
    name: 'USDCoin',
    symbol: 'USDC',
};

export const blastETH: TokenIF = {
    address: '0x0000000000000000000000000000000000000000',
    chainId: 81457,
    decimals: 18,
    fromList: '/ambient-token-list.json',
    logoURI:
        'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png',
    name: 'Ether',
    symbol: 'ETH',
};

export const blastUSDB: TokenIF = {
    address: '0x4300000000000000000000000000000000000003',
    chainId: 81457,
    decimals: 18,
    fromList: '/ambient-token-list.json',
    logoURI:
        'https://assets-global.website-files.com/65a6baa1a3f8ed336f415cb4/65c67f0ebf2f6a1bd0feb13c_usdb-icon-yellow.png',
    name: 'USDB',
    symbol: 'USDB',
};

export const blastORBIT: TokenIF = {
    address: '0x42E12D42b3d6C4A74a88A61063856756Ea2DB357',
    chainId: 81457,
    decimals: 18,
    fromList: '/ambient-token-list.json',
    name: 'Orbit Protocol',
    symbol: 'ORBIT',
    logoURI:
        'https://dashboard-assets.dappradar.com/document/47902/orbitprotocol-project-defi-47902-logo-166x166_1e74c8301cbe3d05a57a80147dd2607d.png',
};

export const blastMIA: TokenIF = {
    address: '0xA4C7aA67189EC5623121c6C94Ec757DfeD932D4B',
    chainId: 81457,
    decimals: 18,
    fromList: '/ambient-token-list.json',
    name: 'Mia',
    symbol: 'MIA',
    logoURI: 'https://miablastsoff.com/images/logo.png',
};

export const blastBAG: TokenIF = {
    address: '0xb9dfCd4CF589bB8090569cb52FaC1b88Dbe4981F',
    chainId: 81457,
    decimals: 18,
    fromList: '/ambient-token-list.json',
    name: 'Bag',
    symbol: 'BAG',
    logoURI: 'https://assets.coingecko.com/coins/images/35417/thumb/bag-cg.png',
};

export const blastBEPE: TokenIF = {
    address: '0xB582Dc28968c725D2868130752aFa0c13EbF9b1a',
    chainId: 81457,
    decimals: 18,
    fromList: '/ambient-token-list.json',
    name: 'Blast Pepe',
    symbol: 'BEPE',
    logoURI:
        'https://assets.coingecko.com/coins/images/35658/standard/logo_200.png',
};

export const blastALIEN: TokenIF = {
    address: '0xCa84812E477eE5a96a92328689D8Ce2589aB6FfD',
    chainId: 81457,
    decimals: 18,
    fromList: '/ambient-token-list.json',
    name: 'Alien',
    symbol: 'ALIEN',
    logoURI: '',
};

export const blastBAJA: TokenIF = {
    address: '0x5FE8534a6F96cb01261Bd96e98c17C2c1Cab3204',
    chainId: 81457,
    decimals: 18,
    fromList: '/ambient-token-list.json',
    name: 'Baja',
    symbol: 'BAJA',
    logoURI: '',
};

export const blastYES: TokenIF = {
    address: '0x20fE91f17ec9080E3caC2d688b4EcB48C5aC3a9C',
    chainId: 81457,
    decimals: 18,
    fromList: '/ambient-token-list.json',
    name: 'Yes',
    symbol: 'YES',
    logoURI: '',
};

export const blastNO: TokenIF = {
    address: '0xAD7039512f3027Df226a3D2c20945E9FbEAf6960',
    chainId: 81457,
    decimals: 18,
    fromList: '/ambient-token-list.json',
    name: 'NoMore',
    symbol: 'NO',
    logoURI:
        'https://raw.githubusercontent.com/SmoreMoney/assets/main/images/Smore.svg',
};

export const blastPACM: TokenIF = {
    address: '0x0B4d0ee29857c3961b380d4ec138EA5814E346b9',
    chainId: 81457,
    decimals: 18,
    fromList: '/ambient-token-list.json',
    name: 'P@cman Blastoff',
    symbol: '$PACM',
    logoURI: '',
};

export const blastWETH: TokenIF = {
    address: '0x4300000000000000000000000000000000000004',
    chainId: 81457,
    decimals: 18,
    fromList: '',
    name: 'Wrapped Ether',
    symbol: 'wETH',
    logoURI: '',
};

export const blastPUMP: TokenIF = {
    address: '0x216A5a1135A9dab49FA9Ad865E0f22FE22b5630A',
    chainId: 81457,
    decimals: 18,
    fromList: '',
    name: 'PUMP',
    symbol: 'PUMP',
    logoURI: '',
};

export const blastOLE: TokenIF = {
    address: '0x73c369f61c90f03eb0dd172e95c90208a28dc5bc',
    chainId: 81457,
    decimals: 18,
    fromList: '/ambient-token-list.json',
    name: 'OpenLeverage',
    symbol: 'OLE',
    logoURI: 'https://blastscan.io/token/images/openleverageole_32.png',
};

export const blastFINGER: TokenIF = {
    address: '0x7217124c626f0b7077be91df939195c9a8184ecc',
    chainId: 81457,
    decimals: 18,
    fromList: '/ambient-token-list.json',
    name: 'Finger Blast',
    symbol: 'FINGER',
    logoURI: 'https://blastscan.io/token/images/fingerblast_32.png',
};

export const blastGLORY: TokenIF = {
    address: '0xd582879453337bd149ae53ec2092b0af5281d1d7',
    chainId: 81457,
    decimals: 18,
    fromList: '/ambient-token-list.json',
    name: 'Sekai Glory',
    symbol: 'GLORY',
    logoURI: 'https://blastscan.io/token/images/sekaiglory_32.png',
};

export const blastVROOM: TokenIF = {
    address: '0x891c9B37177Bdf8Edc891119C9d8aEefDa9A5246',
    chainId: 81457,
    decimals: 18,
    fromList: '',
    name: 'VROOM',
    symbol: 'VROOM',
    logoURI: '',
};

export const blastSepoliaETH: TokenIF = {
    address: '0x0000000000000000000000000000000000000000',
    chainId: 168587773,
    decimals: 18,
    fromList: '/ambient-token-list.json',
    logoURI:
        'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png',
    name: 'Ether',
    symbol: 'ETH',
};

export const blastSepoliaUSDB: TokenIF = {
    address: '0x4200000000000000000000000000000000000022',
    chainId: 168587773,
    decimals: 18,
    fromList: '/ambient-token-list.json',
    logoURI:
        'https://assets-global.website-files.com/65a6baa1a3f8ed336f415cb4/65c67f0ebf2f6a1bd0feb13c_usdb-icon-yellow.png',
    name: 'USDB',
    symbol: 'USDB',
};

export const mainnetWBTC: TokenIF = {
    address: '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
    chainId: 1,
    decimals: 8,
    fromList: '/ambient-token-list.json',
    logoURI:
        'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png',
    name: 'Wrapped BTC',
    symbol: 'WBTC',
};

export const mainnetPEPE: TokenIF = {
    address: '0x6982508145454Ce325dDbE47a25d4ec3d2311933',
    chainId: 1,
    decimals: 18,
    fromList: '/ambient-token-list.json',
    logoURI:
        'https://assets.coingecko.com/coins/images/29850/large/pepe-token.jpeg?1682922725',
    name: 'Pepe',
    symbol: 'PEPE',
};

export const mainnetSYN: TokenIF = {
    address: '0x0f2d719407fdbeff09d87557abb7232601fd9f29',
    chainId: 1,
    decimals: 18,
    fromList: '/ambient-token-list.json',
    logoURI:
        'https://assets.coingecko.com/coins/images/18024/thumb/syn.png?1635002049',
    name: 'Synapse',
    symbol: 'SYN',
};

export const mainnetDAI: TokenIF = {
    address: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
    chainId: 1,
    decimals: 18,
    fromList: '/ambient-token-list.json',
    logoURI:
        'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x6B175474E89094C44Da98b954EedeAC495271d0F/logo.png',
    name: 'Dai Stablecoin',
    symbol: 'DAI',
};

export const mainnetRPL: TokenIF = {
    address: '0xd33526068d116ce69f19a9ee46f0bd304f21a51f',
    chainId: 1,
    decimals: 18,
    fromList: '',
    logoURI: '',
    name: 'Rocket Pool',
    symbol: 'RPL',
};

export const mainnetWstETH: TokenIF = {
    address: '0x7f39C581F595B53c5cb19bD0b3f8dA6c935E2Ca0',
    chainId: 1,
    decimals: 18,
    fromList: '',
    logoURI: '',
    name: 'Wrapped liquid staked Ether 2.0',
    symbol: 'wstETH',
};

export const mainnetMATIC: TokenIF = {
    address: '0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0',
    chainId: 1,
    decimals: 18,
    fromList: '',
    logoURI: '',
    name: 'Matic Token',
    symbol: 'MATIC',
};

export const mainnetFRAX: TokenIF = {
    address: '0x853d955acef822db058eb8505911ed77f175b99e',
    chainId: 1,
    decimals: 18,
    fromList: '/ambient-token-list.json',
    logoURI: 'https://etherscan.io/token/images/fraxfinanceeth2_32.png',
    name: 'Frax',
    symbol: 'FRAX',
};

export const mainnetUSDT: TokenIF = {
    address: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
    chainId: 1,
    decimals: 6,
    fromList: 'https://tokens.coingecko.com/uniswap/all.json',
    logoURI: 'https://assets.coingecko.com/coins/images/325/thumb/Tether.png',
    name: 'Tether',
    symbol: 'USDT',
};

export const goerliETH: TokenIF = {
    name: 'Native Ether',
    address: '0x0000000000000000000000000000000000000000',
    symbol: 'ETH',
    decimals: 18,
    chainId: 5,
    logoURI:
        'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png',
    fromList: '/ambient-token-list.json',
};

export const goerliUSDC: TokenIF = {
    name: 'USDCoin',
    address: '0xD87Ba7A50B2E7E660f678A895E4B72E7CB4CCd9C',
    symbol: 'USDC',
    decimals: 6,
    chainId: 5,
    logoURI:
        'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png',
    fromList: '/ambient-token-list.json',
};

export const sepoliaETH: TokenIF = {
    name: 'Native Ether',
    address: '0x0000000000000000000000000000000000000000',
    symbol: 'ETH',
    decimals: 18,
    chainId: 11155111,
    logoURI:
        'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png',
    fromList: '/ambient-token-list.json',
};

export const sepoliaUSDC: TokenIF = {
    name: 'USDCoin',
    address: '0x60bBA138A74C5e7326885De5090700626950d509',
    symbol: 'USDC',
    decimals: 6,
    chainId: 11155111,
    logoURI:
        'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png',
    fromList: '/ambient-token-list.json',
};

export const sepoliaWBTC: TokenIF = {
    name: 'Wrapped BTC',
    address: '0xCA97CC9c1a1dfA54A252DaAFE9b5Cd1E16C81328',
    symbol: 'WBTC',
    decimals: 8,
    chainId: 11155111,
    logoURI:
        'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png',
    fromList: '/ambient-token-list.json',
};

export const scrollSepoliaWBTC: TokenIF = {
    name: 'Wrapped BTC',
    address: '0xb3B942b6d4a4858838aAb8f94DdaEdd479CD1594',
    symbol: 'WBTC',
    decimals: 8,
    chainId: 534351,
    logoURI:
        'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png',
    fromList: '/ambient-token-list.json',
};

export const goerliDAI: TokenIF = {
    name: 'Dai Stablecoin',
    address: '0xdc31Ee1784292379Fbb2964b3B9C4124D8F89C60',
    symbol: 'DAI',
    decimals: 18,
    chainId: 5,
    logoURI:
        'https://tokens.1inch.io/0x6b175474e89094c44da98b954eedeac495271d0f.png',
    fromList: '/ambient-token-list.json',
};

export const goerliUSDT: TokenIF = {
    name: 'Tether',
    address: '0x509ee0d083ddf8ac028f2a56731412edd63223b9',
    symbol: 'USDT',
    decimals: 6,
    chainId: 5,
    logoURI: 'https://assets.coingecko.com/coins/images/325/thumb/Tether.png',
    fromList: '',
};

export const goerliWBTC: TokenIF = {
    name: 'Wrapped BTC',
    address: '0xC04B0d3107736C32e19F1c62b2aF67BE61d63a05',
    symbol: 'WBTC',
    decimals: 8,
    chainId: 5,
    logoURI:
        'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png',
    fromList: '/ambient-token-list.json',
};

export const arbGoerliETH: TokenIF = {
    name: 'Native Ether',
    address: '0x0000000000000000000000000000000000000000',
    symbol: 'ETH',
    decimals: 18,
    chainId: 421613,
    logoURI:
        'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png',
};

export const arbGoerliUSDC: TokenIF = {
    name: 'USDCoin',
    address: '0xc944b73fba33a773a4a07340333a3184a70af1ae',
    symbol: 'USDC',
    decimals: 6,
    chainId: 421613,
    logoURI:
        'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png',
};

export const arbGoerliWBTC: TokenIF = {
    name: 'Wrapped BTC',
    address: '0x5263e9d82352b8098cc811164c38915812bfc1e3',
    symbol: 'WBTC',
    decimals: 8,
    chainId: 421613,
    logoURI:
        'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png',
};

export const arbGoerliDAI: TokenIF = {
    name: 'Dai Stablecoin',
    address: '0xc52f941486978a25fad837bb701d3025679780e4',
    symbol: 'DAI',
    decimals: 18,
    chainId: 421613,
    logoURI:
        'https://tokens.1inch.io/0x6b175474e89094c44da98b954eedeac495271d0f.png',
};

export const scrollETH: TokenIF = {
    name: 'Native Ether',
    address: '0x0000000000000000000000000000000000000000',
    symbol: 'ETH',
    decimals: 18,
    chainId: 534352,
    logoURI:
        'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png',
};

export const scrollwstETH: TokenIF = {
    name: 'Wrapped stETH',
    address: '0xf610A9dfB7C89644979b4A0f27063E9e7d7Cda32',
    symbol: 'wstETH',
    decimals: 18,
    chainId: 534352,
    logoURI: 'https://scroll-tech.github.io/token-list/data/wstETH/logo.svg',
};

export const scrollRocketPoolETH: TokenIF = {
    name: 'Rocket Pool ETH',
    address: '0x53878B874283351D26d206FA512aEcE1Bef6C0dD',
    symbol: 'rETH',
    decimals: 18,
    chainId: 534352,
    logoURI: '',
};

export const scrollWrappedETH: TokenIF = {
    name: 'Wrapped Ether',
    address: '0x5300000000000000000000000000000000000004',
    symbol: 'wETH',
    decimals: 18,
    chainId: 534352,
    logoURI: '',
};

export const scrollBalancer: TokenIF = {
    name: 'Balancer',
    address: '0x6a28e90582c583fcd3347931c544819C31e9D0e0',
    symbol: 'BAL',
    decimals: 18,
    chainId: 534352,
    logoURI: '',
};

export const scrollUSDC: TokenIF = {
    name: 'USDCoin',
    address: '0x06eFdBFf2a14a7c8E15944D1F4A48F9F95F663A4',
    symbol: 'USDC',
    decimals: 6,
    chainId: 534352,
    logoURI:
        'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png',
};

export const scrollAxlUSDC: TokenIF = {
    name: 'Axelar USDC',
    address: '0xEB466342C4d449BC9f53A865D5Cb90586f405215',
    symbol: 'axlUSDC',
    decimals: 6,
    chainId: 534352,
    logoURI:
        'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png',
};

export const scrollDAI: TokenIF = {
    name: 'Dai Stablecoin',
    address: '0xcA77eB3fEFe3725Dc33bccB54eDEFc3D9f764f97',
    symbol: 'DAI',
    decimals: 18,
    chainId: 534352,
    logoURI:
        'https://tokens.1inch.io/0x6b175474e89094c44da98b954eedeac495271d0f.png',
};

export const scrollUSDT: TokenIF = {
    chainId: 534352,
    address: '0xf55BEC9cafDbE8730f096Aa55dad6D22d44099Df',
    name: 'Tether',
    symbol: 'USDT',
    decimals: 6,
    logoURI: 'https://scroll-tech.github.io/token-list/data/USDT/logo.svg',
};

export const scrollWBTC: TokenIF = {
    chainId: 534352,
    address: '0x3C1BCa5a656e69edCD0D4E36BEbb3FcDAcA60Cf1',
    name: 'Wrapped BTC',
    symbol: 'WBTC',
    decimals: 8,
    logoURI: 'https://scroll-tech.github.io/token-list/data/WBTC/logo.svg',
};

export const scrollSepoliaETH: TokenIF = {
    name: 'Native Ether',
    address: '0x0000000000000000000000000000000000000000',
    symbol: 'ETH',
    decimals: 18,
    chainId: 534351,
    logoURI:
        'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png',
};

export const scrollSepoliaUSDC: TokenIF = {
    name: 'USDCoin',
    address: '0x4D65fB724CEd0CFC6ABFD03231C9CDC2C36A587B',
    symbol: 'USDC',
    decimals: 6,
    chainId: 534351,
    logoURI:
        'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png',
};

export const defaultTokens: TokenIF[] = [
    mainnetETH,
    mainnetUSDC,
    mainnetWBTC,
    mainnetPEPE,
    mainnetDAI,
    mainnetFRAX,
    mainnetSYN,
    mainnetUSDT,
    mainnetRPL,
    mainnetWstETH,
    mainnetMATIC,
    blastETH,
    blastUSDB,
    blastMIA,
    blastORBIT,
    blastBAG,
    blastBAJA,
    blastBEPE,
    blastALIEN,
    blastOLE,
    blastFINGER,
    blastGLORY,
    blastWETH,
    blastPUMP,
    blastYES,
    blastNO,
    blastPACM,
    blastVROOM,
    blastSepoliaETH,
    blastSepoliaUSDB,
    goerliETH,
    goerliUSDC,
    goerliWBTC,
    goerliDAI,
    arbGoerliETH,
    arbGoerliUSDC,
    arbGoerliDAI,
    arbGoerliWBTC,
    scrollETH,
    scrollUSDC,
    scrollDAI,
    scrollUSDT,
    scrollDAI,
    scrollBalancer,
    scrollAxlUSDC,
    scrollWBTC,
    scrollRocketPoolETH,
    scrollWrappedETH,
    scrollwstETH,
    sepoliaETH,
    sepoliaUSDC,
    sepoliaWBTC,
    scrollSepoliaETH,
    scrollSepoliaUSDC,
    scrollSepoliaWBTC,
];
