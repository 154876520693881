import styles from './NotFoundLottie.module.css';
// import Animation from '../../Global/Animation/Animation';
// import NotFound from '../../../assets/animations/NotFound.json';
import { Link } from 'react-router-dom';
import { PURPLE } from '../../../constants';

export default function NotFoundLottie() {
    return (
        <div className={styles.notfound_container}>
            {/* <Animation animData={NotFound} loop /> */}
            <div
                className={styles.content}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: PURPLE,
                    border: '3px solid',
                    borderRadius: 12,
                    padding: '8px',
                }}
            >
                {/* Click */}
                <Link to='/'>Return Home</Link>
                {/* to go back home */}
            </div>
        </div>
    );
}
