/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable quotes */
// import { useContext, useState } from 'react';
// import styled from 'styled-components/macro'
import React from 'react';
import More from '../../components/More/More';
import styles from './More.module.css';
// import { PINK_PRIMARY } from '../../constants';

export default function MorePage() {
    // const isMobile =
    //     window.innerWidth < 624 ||
    //     navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i);

    return (
        <div className={styles.more_page_container}>
            <More />
        </div>
    );
}
