import styles from './SidebarFooter.module.css';
import { Link, useLocation } from 'react-router-dom';
import { MdAccountBox, MdOutlineExplore } from 'react-icons/md';
// import { RiSwapBoxFill } from 'react-icons/ri';
// import { RiUserSmileFill } from 'react-icons/ri';
import { ImWink2 } from 'react-icons/im';

import { FaGrinTongue, FaFrown, FaSmile } from 'react-icons/fa';
// import { GiChocolateBar } from 'react-icons/gi';

// import { GiTrade, GiMarshmallows } from 'react-icons/gi';
// import { BsFillChatDotsFill, BsFillEmojiSmileFill } from 'react-icons/bs';
import { memo, useContext } from 'react';
import { formSlugForPairParams } from '../../../../App/functions/urlSlugs';
import { chainNumToString } from '../../../../ambient-utils/dataLayer';
import { TradeDataContext } from '../../../../contexts/TradeDataContext';
import { SidebarContext } from '../../../../contexts/SidebarContext';
// import { VscLayoutSidebarLeft } from 'react-icons/vsc';

function SidebarFooter() {
    const location = useLocation();

    const currentLocation = location.pathname;

    const sidebarPositionStyle =
        currentLocation === '/'
            ? styles.position_sticky
            : styles.position_sticky;

    // const tradeDestination = location.pathname.includes('trade/market')
    //     ? '/trade/market/'
    //     : location.pathname.includes('trade/limit')
    //     ? '/trade/limit/'
    //     : location.pathname.includes('trade/pool')
    //     ? '/trade/pool/'
    //     : location.pathname.includes('trade/edit')
    //     ? '/trade/edit/'
    //     : '/trade/market/';

    const { tokenA, tokenB } = useContext(TradeDataContext);

    const paramsSlug = formSlugForPairParams({
        chain: chainNumToString(tokenA.chainId),
        tokenA: tokenA.address,
        tokenB: tokenB.address,
    });

    const { hideOnMobile, toggleMobileModeVisibility, sidebar } =
        useContext(SidebarContext);

    // const handleSidebarCloseAndOpen = () => {
    //     toggleMobileModeVisibility();

    //     if (!sidebar.isOpen) {
    //         sidebar.open(true);
    //     }
    // };

    const linksData = [
        // {
        //     title: 'Sidebar ',
        //     onClick: handleSidebarCloseAndOpen,
        //     icon: VscLayoutSidebarLeft,
        //     isButton: true,
        // },
        // {
        //     title: 'YES',
        //     destination: '/', // '/swap/' + paramsSlug,
        //     icon: FaSmile,
        //     // isButton: true,
        // },
        // {
        //     title: 'Bridge',
        //     destination: '/bridge',
        //     icon: GiMarshmallows,
        // },
        // {
        //     title: 'NO',
        //     destination: '/', // '/mint',
        //     icon: FaGrinTongue,
        // },
        {
            title: 'EXIT',
            destination: '/exit', // '/mint',
            icon: FaFrown,
        },
        // {
        //     title: 'MAYBE',
        //     destination: '/', // '/maybe',
        //     icon: ImWink2,
        //     // icon: GiChocolateBar,
        // },
        // {
        //     title: 'MORE',
        //     destination: '/more',
        //     icon: GiChocolateBar,
        // },
        // {
        //     title: 'DOCS',
        //     destination: '/maybe',
        //     icon: ImWink2,
        //     // icon: GiChocolateBar,
        // },
        // {
        //     title: 'Trade',
        //     destination: tradeDestination + paramsSlug,
        //     icon: GiTrade,
        // },
        // {
        //     title: 'Explore',
        //     destination: '/explore',
        //     icon: MdOutlineExplore,
        // },
        // { title: 'Portfolio', destination: '/account/', icon: MdAccountBox },
        // { title: 'Chat', destination: '/chat/', icon: BsFillChatDotsFill },
    ];
    return (
        <div
            className={`${styles.sidebar_footer} ${sidebarPositionStyle}`}
            style={{ paddingLeft: !hideOnMobile ? '1.5rem' : '' }}
        >
            {linksData.map((link) =>
                // link.isButton ? (
                //     <span onClick={link.onClick} key={link.title}>
                //         <link.icon size={18} color='var(--text-highlight)' />
                //         <p>{link.title}</p>
                //     </span>
                // ) :
                link.destination ? (
                    <Link to={link.destination} key={link.destination}>
                        <link.icon
                            size={18}
                            color={
                                currentLocation === link.destination
                                    ? '#e696ac' // 'var(--accent1)'
                                    : 'var(--text-highlight)'
                            }
                        />
                        <p>{link.title}</p>
                    </Link>
                ) : (
                    ''
                ),
            )}
        </div>
    );
}

export default memo(SidebarFooter);
