/* eslint-disable quotes */
// START: Import React and Dongles
import { useRef, useEffect, memo, useContext } from 'react';
import { CSSTransition } from 'react-transition-group';
// import { AiFillTwitterCircle } from 'react-icons/ai';
// import { IoDocumentTextSharp } from 'react-icons/io5';
// import { BsMedium } from 'react-icons/bs';
import { SiGitbook } from 'react-icons/si';
// import { BsTwitterX } from 'react-icons/bs';
import { FaXTwitter } from 'react-icons/fa6';

// import { RiSpyFill } from 'react-icons/ri';
import {
    FaDiscord,
    FaTwitter,
    FaTelegramPlane,
    FaCampground,
} from 'react-icons/fa'; // FaHome, FaCannabis, FaFire
import '../../../App.css';
import useKeyPress from '../../../hooks/useKeyPress';
import { openInNewTab } from '../../../../ambient-utils/dataLayer';
import {
    BRIDGE_LINK,
    DISCORD_LINK,
    DOCS_LINK,
    TELEGRAM_LINK,
    // MEDIUM_LINK,
    TWITTER_LINK,
} from '../../../../ambient-utils/constants';
// import { useTermsAgreed } from '../../../hooks/useTermsAgreed';
import { LogoutButton } from '../../../../components/Global/LogoutButton/LogoutButton';
import { AppStateContext } from '../../../../contexts/AppStateContext';
import {
    NavbarDropdown,
    Menu,
    NavbarLogoutContainer,
} from '../../../../styled/Components/Header';
import NavbarDropdownItem from './NavbarDropdownItem';
import { GiSplitCross } from 'react-icons/gi';

interface propsIF {
    isUserLoggedIn: boolean | undefined;
    clickLogout: () => void;
    closeMenu?: () => void;
    setIsNavbarMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function NavbarDropdownMenu(props: propsIF) {
    const { closeMenu, clickLogout, setIsNavbarMenuOpen, isUserLoggedIn } =
        props;

    const {
        wagmiModal: { open: openWagmiModal },
    } = useContext(AppStateContext);

    // const [, , termsUrls] = useTermsAgreed();

    const dropdownRef = useRef(null);

    const isEscapePressed = useKeyPress('Escape');

    useEffect(() => {
        if (isEscapePressed) {
            setIsNavbarMenuOpen(false);
        }
    }, [isEscapePressed]);

    const ariaLabel =
        'You are currently on a focus mode on the main dropdown menu. To enter focus mode, press tab once again.  To exit focus mode, press escape.';

    interface navDataIF {
        icon: JSX.Element;
        resource: string;
        text: string;
    }

    const navData: navDataIF[] = [
        // {
        //     // icon: <FaHome size={20} />,
        //     icon: <FaCampground size={20} />,
        //     resource: 'https://smore.money',
        //     text: `S'more Money`,
        // },
        {
            icon: <GiSplitCross size={20} />,
            resource: BRIDGE_LINK,
            text: 'Bridge',
        },
        {
            icon: <FaTelegramPlane size={20} />,
            resource: TELEGRAM_LINK,
            text: 'Telegram',
        },
        {
            // icon: <AiFillTwitterCircle size={20} />,
            // icon: <FaTwitter size={20} />,
            icon: <FaXTwitter size={20} />,
            resource: TWITTER_LINK,
            text: 'Twitter',
        },
        {
            icon: <FaDiscord size={20} />,
            resource: DISCORD_LINK,
            text: 'Discord',
        },
        {
            icon: <SiGitbook size={20} />,
            resource: DOCS_LINK,
            text: 'LitePaper',
        },
        // {
        //     icon: <BsMedium size={20} />,
        //     resource: MEDIUM_LINK,
        //     text: 'Medium',
        // },
        // {
        //     icon: <RiSpyFill size={20} />,
        //     resource: `${window.location.origin}/${termsUrls.privacy}`,
        //     text: 'Privacy',
        // },
        // {
        //     icon: <IoDocumentTextSharp size={20} />,
        //     resource: `${window.location.origin}/${termsUrls.tos}`,
        //     text: 'Terms of Service',
        // },
    ];

    return (
        <NavbarDropdown ref={dropdownRef} aria-label={ariaLabel} hasBorder>
            <CSSTransition
                in={true}
                unmountOnExit
                timeout={300}
                classNames='menu-primary'
            >
                {/* Menu with each drop down item */}
                <Menu
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5 }}
                    tabIndex={0}
                >
                    {navData.map((item: navDataIF) => (
                        <NavbarDropdownItem
                            key={item.text}
                            rightIcon={item.icon}
                            onClick={() => {
                                openInNewTab(item.resource);
                                closeMenu && closeMenu();
                            }}
                        >
                            {item.text}
                        </NavbarDropdownItem>
                    ))}
                    {isUserLoggedIn ? (
                        <NavbarLogoutContainer>
                            <LogoutButton
                                onClick={() => {
                                    clickLogout();
                                    closeMenu && closeMenu();
                                }}
                            />
                        </NavbarLogoutContainer>
                    ) : (
                        <NavbarLogoutContainer>
                            <NavbarDropdownItem
                                connectButton
                                onClick={openWagmiModal}
                            >
                                Connect Wallet
                            </NavbarDropdownItem>
                        </NavbarLogoutContainer>
                    )}
                </Menu>
            </CSSTransition>
        </NavbarDropdown>
    );
}

export default memo(NavbarDropdownMenu);
