/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable quotes */
import React, { useContext, useState } from 'react';
import styled from 'styled-components/macro';
import Exit from '../../components/Exit/Exit';
// @ts-ignore
import styles from './Exit.module.css';
// import { PINK_PRIMARY } from '../../constants';

export default function ExitPage() {
    const isMobile =
        window.innerWidth < 624 ||
        navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i);

    return (
        <div className={styles.exit_page_container}>
            <Exit />
            {/* <div
                style={{
                    display: 'flex',
                    width: isMobile ? window.innerWidth + 'px' : window.innerWidth * 0.65 + 'px',
                    // height: isMobile ? window.innerHeight + 'px' : window.innerHeight * 0.65 + 'px',
                    border: '3px solid #ce6C87',
                    borderRadius: 12,
                    // marginTop: 12,
                    backgroundColor: '#15120e', // brown
                    color: PINK_PRIMARY,
                    fontSize: '1.2rem',
                    fontStyle: 'bold',
                    justifyContent: 'center',
                    fontWeight: 'bold',
                }}
            
            >
            <iframe
                // src="https://dexscreener.com/blast/0xb58fd25e1b9657b5bb511601ed7b0be1ae776996"
                // src='https://app.thruster.finance/?token1=0x20fE91f17ec9080E3caC2d688b4EcB48C5aC3a9C&token2=0xAD7039512f3027Df226a3D2c20945E9FbEAf6960'
                // <style>#dexscreener-embed{position:relative;width:100%;padding-bottom:125%;}@media(min-width:1400px){#dexscreener-embed{padding-bottom:65%;}}#dexscreener-embed iframe{position:absolute;width:100%;height:100%;top:0;left:0;border:0;}</style><div id="dexscreener-embed"><iframe src="https://dexscreener.com/blast/0xB58Fd25E1b9657B5bb511601ed7b0bE1aE776996?embed=1&theme=dark"></iframe></div>
                // src={'https://dexscreener.com/blast/0xB58Fd25E1b9657B5bb511601ed7b0bE1aE776996?embed=1&theme=dark'}
                // src={'https://dexscreener.com/blast/0xB58Fd25E1b9657B5bb511601ed7b0bE1aE776996?embed=1&theme=dark&trades=0&info=0'}
                src={'https://dexscreener.com/blast/0xB58Fd25E1b9657B5bb511601ed7b0bE1aE776996?embed=1&theme=dark&trades=0&info=0'}
                title='Blast NO'
                frameBorder={0}
                style={{
                    display: 'flex',
                    width: isMobile ? window.innerWidth + 'px' : window.innerWidth * 0.65 + 'px',
                    height: isMobile ? '12rem' : '24rem',
                    fontSize: '1.2rem',
                    fontStyle: 'bold',
                    justifyContent: 'center',
                    fontWeight: 'bold',
                    borderRadius: 12
                }}
            />
            </div> */}
        </div>
    );
}
