export enum ChainId {
    ETHEREUM = 1,
    BASE = 8453,
    MATIC = 137,
    FANTOM = 250,
    BSC = 56,
    BLAST = 81457, // 0x13e31
    AVALANCHE = 43114,
    ARBITRUM = 42161,
    MANTLE = 5000,
}

const ethereum = {
    chainId: ChainId.ETHEREUM,
    name: 'Ethereum',
    currency: 'ETH',
    explorerUrl: 'https://etherscan.io',
    rpcUrl: 'https://rpc.ankr.com/eth',
};
const base = {
    chainId: ChainId.BASE,
    name: 'Coinbase',
    currency: 'ETH',
    explorerUrl: 'https://basescan.org',
    rpcUrl: 'https://rpc.ankr.com/base',
};
const fantom = {
    chainId: ChainId.FANTOM,
    name: 'Fantom Opera',
    currency: 'FTM',
    explorerUrl: 'https://ftmscan.com',
    rpcUrl: 'https://rpc.ankr.com/fantom',
};
const arbitrum = {
    chainId: ChainId.ARBITRUM,
    name: 'Arbitrum',
    currency: 'ETH',
    explorerUrl: 'https://arbiscan.io',
    rpcUrl: 'https://rpc.ankr.com/arbitrum',
};
const avalanche = {
    chainId: ChainId.AVALANCHE,
    name: 'Avalanche',
    currency: 'AVAX',
    explorerUrl: 'https://snowtrace.io',
    rpcUrl: 'https://rpc.ankr.com/avalanche',
};

const mantle = {
    chainId: ChainId.MANTLE,
    name: 'Mantle',
    currency: 'MNT',
    explorerUrl: 'https://mantlescan.info',
    rpcUrl: 'https://rpc.ankr.com/mantle',
};

const blast = {
    chainId: ChainId.BLAST,
    name: 'Blast',
    currency: 'ETH',
    explorerUrl: 'https://blastscan.io',
    // rpcUrl: 'https://rpc.ankr.com/blast',
    rpcUrl: 'https://rpc.blast.io',
    // rpcUrl: "https://rpc.blast.io",
    // rpcUrl: "https://rpc.blastblockchain.com",
    // logoUrl: "https://assets-global.website-files.com/65a6baa1a3f8ed336f415cb4/65a6c39bae6093c6653dd016_Logo%20Yellow%20on%20Black%20Background%202x-p-500.png"
};

export const chains = [
    ethereum,
    base,
    mantle,
    fantom,
    blast,
    arbitrum,
    avalanche,
];
