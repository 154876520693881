import styles from './WalletButton.module.css';
// import smoreWallet from '../../../../assets/images/logos/logo_mark.svg';
import smoreWallet from '../../../../assets/images/logos/logo.svg';

interface WalletButtonPropsIF {
    disabled?: boolean;
    title: string;
    logo: HTMLElement & SVGElement;
    action: () => void;
}

export default function WalletButton(props: WalletButtonPropsIF) {
    const ariaLabel = `connect to ${props.title} wallet.`;
    return (
        <button
            disabled={props.disabled}
            onClick={() => props.action()}
            className={styles.container}
            style={
                props.disabled
                    ? { cursor: 'default' }
                    : {
                          border: '4px solid #A480FF',
                          // backgroundColor: '#640dbe',
                      }
            }
            tabIndex={0}
            aria-label={ariaLabel}
        >
            {props.logo && (
                <img
                    className={styles.icon}
                    src={props.logo ? props.logo : smoreWallet}
                    alt=''
                    height={64}
                    width={64}
                />
            )}
            <div className={styles.wallet_name}>{props.title}</div>
        </button>
    );
}
