/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable quotes */
import React, { memo, useContext, useEffect, useState } from 'react';
// import styled from 'styled-components/macro';
// import { TbArrowBigDownLineFilled } from 'react-icons/tb';
import { PiArrowFatLinesDownFill } from 'react-icons/pi';

import {
    NO_ADDRESS,
    YES_ADDRESS,
    ABI_NO,
    ABI_ERC20,
    PINK_PRIMARY,
    PINK_SECONDARY,
    BLACK,
} from '../../constants';
import { TAN, YELLOW, PURPLE, WHITE } from '../../constants/index';
import { UserDataContext } from '../../contexts/UserDataContext';
import { FlexContainer } from '../../styled/Common';

import { useContract } from 'wagmi';
import { CrocEnvContext } from '../../contexts/CrocEnvContext';
import { getSigner } from '../../utils';
import { ethers } from 'ethers';
import Logo from '../Logo';
import { usePlayerInfo } from '../../utils/hooks/useAPI';

function Mint() {
    const {
        // crocEnv,
        chainData: { chainId },
        // ethMainnetUsdPrice,
        // provider,
    } = useContext(CrocEnvContext);

    // amounts //
    const [amount, setAmount] = useState('');
    // const [balance, setBalance] = useState('0');
    const [showSwap, setShowSwap] = useState(false);
    const { isUserConnected, userAddress } = useContext(UserDataContext);
    // const library = new ethers.providers.JsonRpcProvider(
    //     //   @ts-ignore
    //     RPC[chainId].toString(),
    // );
    const account =
        userAddress?.toString() ?? '0xFd63Bf84471Bc55DD9A83fdFA293CCBD27e1F4C8';
    const library = userAddress
        ? // @ts-ignore
          new ethers.providers.Web3Provider(window?.ethereum)
        : new ethers.providers.JsonRpcProvider('https://rpc.blast.io/');
    // const signer = library.getSigner(account).connectUnchecked();

    //  USER INFO //
    const { playerInfo } = usePlayerInfo(account);
    const yesBalance = isUserConnected ? Number(playerInfo?.yesBalance) : 0;

    // @ts-ignore
    const signer = getSigner(library, account); // .connectUnchecked();
    const YesContract = useContract({
        address: YES_ADDRESS,
        abi: ABI_ERC20,
        signerOrProvider: signer,
    });

    const NoContract = useContract({
        address: NO_ADDRESS,
        abi: ABI_NO,
        signerOrProvider: signer,
    });

    // HANDLES: MAX //
    // const handleMax = async () => {
    //     // console.log('amount: %s', amount);
    //     const _balance = await YesContract?.balanceOf(account);
    //     const balance = _balance / 1e18;
    //     console.log('balance: %s', balance);
    //     await setAmount(balance?.toString());
    // };

    // HANDLES: APPROVAL //
    const handleApprove = async (amount: string) => {
        // console.log('amount: %s', amount);
        YesContract?.approve(NO_ADDRESS, amount);
    };

    // HANDLES: MINT //
    const handleMint = async (amount: string) => {
        console.log('amount: %s', amount);
        NoContract?.mint(userAddress, amount);
    };

    // const titleText = `Mint (S'more) $NO`;
    const isTiny = window.innerWidth < 424;
    const isMobile = window.innerWidth < 768;
    const mobileLR = '4px solid #ce6C87';
    const mobileTB = '4px solid #E696AC';

    const toggleShow = (isSwap: boolean) => {
        setShowSwap(isSwap);
    };

    return (
        <FlexContainer
            // fullHeight
            // fullWidth
            style={{
                display: 'flex',
                // flexGrow: 1,
                flexDirection: 'column',
                // width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                alignContent: 'center',
                marginTop: isMobile ? '4rem' : '6rem',
                borderTop: isMobile ? mobileTB : '12px solid #CE6C87',
                borderLeft: isMobile ? mobileLR : '12px solid #A480FF',
                borderBottom: isMobile ? mobileTB : '12px solid #CE6C87',
                borderRight: isMobile ? mobileLR : '12px solid #A480FF',
                borderRadius: 48,
                padding: isMobile ? 18 : 24,
                backgroundColor: 'rgb(246, 177, 195)',
            }}
        >
            {isMobile && (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        justifyItems: 'center',
                        textAlign: 'center',
                        backgroundColor: PINK_PRIMARY,
                        fontSize: '1.2rem',
                        fontStyle: 'bold',
                        height: isMobile ? 32 : 64,
                        width: '100%',
                        // gap: 4,
                        marginTop: 4,
                        border: '4px solid #A480FF', // PURPLE
                        borderRadius: 12,
                    }}
                >
                    <button
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            justifyItems: 'center',
                            textAlign: 'center',
                            width: '100%',
                            height: isMobile ? 32 : 64,
                            border: '2px solid #A480FF',
                            borderRadius: 8,
                            marginTop: 24,
                            marginBottom: 24,
                            backgroundColor:
                                !showSwap && !isMobile ? WHITE : PURPLE,
                            color: !showSwap && !isMobile ? PURPLE : WHITE,
                            fontSize: '1.2rem',
                            fontStyle: 'bold',
                        }}
                        //   onClick={() => toggleShow(false)}
                    >
                        {`MINT NO`}
                    </button>
                </div>
            )}
            {!isMobile && (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        justifyItems: 'center',
                        textAlign: 'center',
                        backgroundColor: PINK_PRIMARY,
                        fontSize: '1.2rem',
                        fontStyle: 'bold',
                        height: isMobile ? 32 : 64,
                        width: '100%',
                        // gap: 4,
                        marginTop: 4,
                        border: '4px solid #A480FF',
                        borderRadius: 12,
                    }}
                >
                    <button
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            justifyItems: 'center',
                            textAlign: 'center',
                            width: '100%',
                            height: isMobile ? 32 : 64,
                            border: '2px solid #A480FF',
                            borderRadius: 8,
                            marginTop: 24,
                            marginBottom: 24,
                            backgroundColor: !showSwap ? WHITE : PURPLE,
                            color: !showSwap ? PURPLE : WHITE,
                            fontSize: '1.2rem',
                            fontStyle: 'bold',
                        }}
                        onClick={() => toggleShow(false)}
                    >
                        {`MINT`}
                    </button>
                    <button
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            justifyItems: 'center',
                            textAlign: 'center',
                            width: '100%',
                            height: isMobile ? 32 : 64,
                            border: '2px solid #A480FF',
                            borderRadius: 8,
                            marginTop: 24,
                            marginBottom: 24,
                            backgroundColor: showSwap ? WHITE : PURPLE,
                            color: showSwap ? PURPLE : WHITE,
                            fontSize: '1.2rem',
                            fontStyle: 'bold',
                        }}
                        onClick={() => toggleShow(true)}
                    >
                        {`BUY`}
                    </button>
                </div>
            )}
            {!showSwap && (
                <div
                    style={{
                        // display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        // backgroundColor: TAN,
                        color: PURPLE,
                        fontSize: '1.2rem',
                        fontStyle: 'bold',
                        width: isTiny ? '18rem' : isMobile ? '24rem' : '32rem',
                        // width: window.innerWidth * 0.85,
                        // height: window.innerHeight * 0.65,
                        fontWeight: 'bold',
                        maxWidth: 1200,
                    }}
                >
                    <head>
                        <title>{"S'mint NO"}</title>
                        <meta name='description' content={"S'mint $NO"} />
                        <link rel='icon' href='/bannerImage.png' />
                    </head>

                    {/* INPUT DATA */}
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            // backgroundColor: TAN,
                            color: WHITE,
                            fontSize: '1.2rem',
                            fontStyle: 'bold',
                            // height: isMobile ? 32 : 64,
                            width: '100%',
                            gap: 12,
                            marginTop: isMobile ? 12 : 24,
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                // justifyContent: 'space-between',
                                justifyContent: 'center',
                                backgroundColor: TAN,
                                color: YELLOW,
                                fontSize: '1.2rem',
                                fontStyle: 'bold',
                                height: isMobile ? 48 : 64,
                                width: 248,
                                border: '2px solid',
                                borderRadius: 12,
                                gap: 24,
                            }}
                        >
                            <Logo
                                address={YES_ADDRESS}
                                height={isMobile ? 24 : 36}
                                width={isMobile ? 24 : 36}
                            />
                            {'YES'}
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                width: '100%',
                            }}
                        >
                            <input
                                value={amount}
                                placeholder={yesBalance.toFixed(2).toString()} // 'Enter Amount'
                                onChange={(e) => setAmount(e.target.value)}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    textAlign: 'center',
                                    backgroundColor: TAN,
                                    borderColor: YELLOW,
                                    color: YELLOW,
                                    fontSize: '1.2rem',
                                    fontStyle: 'bold',
                                    height: isMobile ? 48 : 64,
                                    width: '100%',
                                    border: '2px solid',
                                    borderRadius: 12,
                                }}
                            />

                            {/* <button
                                // onClick={() => handleMax()}
                                style={{
                                    border: '2px solid',
                                    background: YELLOW,
                                    justifyItems: 'center',
                                    alignContent: 'center',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                    borderRadius: 12,
                                    padding: 2,
                                    display: 'flex',
                                    justifyContent: 'end',
                                    // flexDirection: 'column',
                                    // width: '20%',
                                    fontSize: isMobile ? 12 : 16,
                                    fontWeight: 'bold',

                                }}
                            >
                                {`${balance}`}
                            </button> */}
                        </div>
                    </div>

                    {/*     DOWN ARROW ICON     */}
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'end',
                            position: 'sticky',
                            marginRight: 24,
                            padding: 2,
                            marginTop: -14,
                            marginBottom: -14,
                            // height: 2,
                        }}
                    >
                        <PiArrowFatLinesDownFill
                            style={{
                                border: '2px solid',
                                borderRadius: 12,
                                backgroundColor: BLACK,
                                color: PURPLE,
                                height: 24,
                                width: 28,
                                margin: 2,
                                // padding: 2,
                            }}
                        />
                    </div>

                    {/* OUTPUT DATA */}
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            // backgroundColor: TAN,
                            color: WHITE,
                            fontSize: '1.2rem',
                            fontStyle: 'bold',
                            // height: isMobile ? 32 : 64,
                            width: '100%',
                            gap: 12,
                            // marginTop: isMobile ? 12 : 36,
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                // justifyContent: 'space-between',
                                justifyContent: 'center',
                                backgroundColor: TAN,
                                color: PURPLE,
                                fontSize: '1.2rem',
                                fontStyle: 'bold',
                                height: isMobile ? 48 : 64,
                                width: 248,
                                border: '2px solid',
                                borderRadius: 12,
                                gap: 24,
                            }}
                        >
                            <Logo
                                address={NO_ADDRESS}
                                height={isMobile ? 24 : 36}
                                width={isMobile ? 24 : 36}
                            />
                            {'NO'}
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: TAN,
                                borderColor: YELLOW,
                                color: PURPLE,
                                fontSize: '1.2rem',
                                fontStyle: 'bold',
                                height: isMobile ? 48 : 64,
                                width: '100%',
                                border: '2px solid',
                                borderRadius: 12,
                            }}
                        >
                            {amount ?? 0}
                        </div>
                    </div>

                    {/* <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        width: '100%',
                                        // height: '12px',
                                        border: '1px solid #ce6C87',
                                        marginTop: 32,
                                        borderRadius: 32,
                                    }}
                                /> */}
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: PINK_SECONDARY,
                            color: WHITE,
                            fontSize: '1.2rem',
                            fontStyle: 'bold',
                            width: '100%',
                            marginTop: isMobile ? 12 : 18,
                            borderRadius: 12,
                            fontWeight: 'bold',
                            border: '3px solid #ce6C87', // pink primary
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                // padding: '12px',
                                marginTop: '0.85rem',
                            }}
                        >
                            {/* TODO: Make Dynamic */}
                            {`1 NO = 1 YES`}
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                marginBottom: '0.5rem',
                                fontSize: '1rem',
                            }}
                        >
                            {`Note: rate increases over time...`}
                        </div>
                    </div>

                    {/* APPROVE BUTTON */}
                    {isUserConnected && (
                        <button
                            onClick={
                                () =>
                                    handleApprove(
                                        (Number(amount) * 1e18).toString(),
                                    )
                                //   : () => open({ view: 'Connect' })
                            }
                            disabled={!isUserConnected}
                            style={{
                                display: 'flex',
                                width: '100%',
                                border: '3px solid #ce6C87',
                                borderRadius: 12,
                                marginTop:
                                    isUserConnected && isMobile ? 12 : 24,
                                backgroundColor: '#15120e', // brown
                                color: PINK_PRIMARY,
                                fontSize: '1.2rem',
                                fontStyle: 'bold',
                                justifyContent: 'center',
                                padding: '1rem',
                                fontWeight: 'bold',
                            }}
                        >
                            {isUserConnected
                                ? `Approve YES`
                                : 'Wallet Disconnected'}
                        </button>
                    )}

                    {/* MINT BUTTON */}

                    <button
                        onClick={() =>
                            handleMint((Number(amount) * 1e18).toString())
                        }
                        disabled={!isUserConnected || !amount}
                        style={{
                            display: 'flex',
                            width: '100%',
                            border: '3px solid #ce6C87',
                            borderRadius: 12,
                            marginTop: isMobile ? 4 : 4,
                            backgroundColor: '#15120e', // brown
                            color: PINK_PRIMARY,
                            fontSize: '1.2rem',
                            fontStyle: 'bold',
                            justifyContent: 'center',
                            padding: '1rem',
                            fontWeight: 'bold',
                            // height: '1rem',
                        }}
                    >
                        {!amount
                            ? 'Enter Amount'
                            : userAddress
                            ? `Mint NO`
                            : 'Wallet Disconnected'}
                    </button>
                </div>
            )}
            {isMobile && (
                <button
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignContent: 'center',
                        width: '100%',
                        textAlign: 'center',
                        justifyItems: 'center',
                        borderRadius: 12,
                        marginTop: 4,
                        backgroundColor: '#15120e', // brown
                        color: PURPLE,
                        fontSize: '1.2rem',
                        fontStyle: 'bold',
                        justifyContent: 'center',
                        padding: '1rem',
                        fontWeight: 'bold',
                        height: '4px',
                        border: '3px solid #A480FF',
                    }}
                >
                    <a
                        href={
                            'https://app.thruster.finance/?token1=0x20fE91f17ec9080E3caC2d688b4EcB48C5aC3a9C&token2=0xAD7039512f3027Df226a3D2c20945E9FbEAf6960'
                        }
                        target='_blank'
                        rel='noreferrer'
                        style={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'center',
                        }}
                    >
                        {/* {`Buy $NO on Thruster ↗`} */}
                        {`Buy $NO on Thruster`}
                    </a>
                </button>
            )}
            {showSwap && (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: isMobile
                            ? window.innerWidth * 0.85
                            : window.innerWidth * 0.6,
                        height: window.innerHeight * 0.5,
                        // height: '12px',
                        // border: '1px solid #ce6C87',
                        marginTop: 12,
                        // borderRadius: 32,
                    }}
                >
                    <iframe
                        // src="https://dexscreener.com/blast/0xb58fd25e1b9657b5bb511601ed7b0be1ae776996"
                        // src='https://app.thruster.finance/?token1=0x20fE91f17ec9080E3caC2d688b4EcB48C5aC3a9C&token2=0xAD7039512f3027Df226a3D2c20945E9FbEAf6960'
                        // <style>#dexscreener-embed{position:relative;width:100%;padding-bottom:125%;}@media(min-width:1400px){#dexscreener-embed{padding-bottom:65%;}}#dexscreener-embed iframe{position:absolute;width:100%;height:100%;top:0;left:0;border:0;}</style><div id="dexscreener-embed"><iframe src="https://dexscreener.com/blast/0xB58Fd25E1b9657B5bb511601ed7b0bE1aE776996?embed=1&theme=dark"></iframe></div>
                        // src={'https://dexscreener.com/blast/0xB58Fd25E1b9657B5bb511601ed7b0bE1aE776996?embed=1&theme=dark'}
                        src={
                            'https://dexscreener.com/blast/0xB58Fd25E1b9657B5bb511601ed7b0bE1aE776996?embed=1&theme=dark&trades=0&info=0'
                        }
                        title='Blast NO'
                        style={{
                            display: 'flex',
                            width: '100%',
                            height: '100%',
                            border: '3px solid #ce6C87',
                            borderRadius: 12,
                            // marginTop: 12,
                            backgroundColor: '#15120e', // brown
                            color: PINK_PRIMARY,
                            fontSize: '1.2rem',
                            fontStyle: 'bold',
                            justifyContent: 'center',
                            padding: '1rem',
                            fontWeight: 'bold',
                        }}
                    />
                    <a
                        href={
                            'https://app.thruster.finance/?token1=0x20fE91f17ec9080E3caC2d688b4EcB48C5aC3a9C&token2=0xAD7039512f3027Df226a3D2c20945E9FbEAf6960'
                        }
                        target='_blank'
                        rel='noreferrer'
                    >
                        <button
                            style={{
                                display: 'flex',
                                width: '100%',
                                border: '3px solid #ce6C87',
                                borderRadius: 12,
                                marginTop: 12,
                                backgroundColor: '#15120e', // brown
                                color: PINK_PRIMARY,
                                fontSize: '1.2rem',
                                fontStyle: 'bold',
                                justifyContent: 'center',
                                padding: '1rem',
                                fontWeight: 'bold',
                            }}
                        >
                            {/* {`Buy $NO on Thruster ↗`} */}
                            {`Buy $NO on Thruster`}
                        </button>
                    </a>
                </div>
            )}
            {/* { !showSwap &&
             <div
                style={{
                    display: 'flex',
                    width: isMobile ? window.innerWidth + 'px' : window.innerWidth * 0.5 + 'px',
                    // height: isMobile ? window.innerHeight + 'px' : window.innerHeight * 0.65 + 'px',
                    border: '3px solid #ce6C87',
                    borderRadius: 12,
                    // marginTop: 12,
                    backgroundColor: '#15120e', // brown
                    color: PINK_PRIMARY,
                    fontSize: '1.2rem',
                    fontStyle: 'bold',
                    justifyContent: 'center',
                    fontWeight: 'bold',
                }}
            
            >
            <iframe
                // src="https://dexscreener.com/blast/0xb58fd25e1b9657b5bb511601ed7b0be1ae776996"
                // src='https://app.thruster.finance/?token1=0x20fE91f17ec9080E3caC2d688b4EcB48C5aC3a9C&token2=0xAD7039512f3027Df226a3D2c20945E9FbEAf6960'
                // <style>#dexscreener-embed{position:relative;width:100%;padding-bottom:125%;}@media(min-width:1400px){#dexscreener-embed{padding-bottom:65%;}}#dexscreener-embed iframe{position:absolute;width:100%;height:100%;top:0;left:0;border:0;}</style><div id="dexscreener-embed"><iframe src="https://dexscreener.com/blast/0xB58Fd25E1b9657B5bb511601ed7b0bE1aE776996?embed=1&theme=dark"></iframe></div>
                // src={'https://dexscreener.com/blast/0xB58Fd25E1b9657B5bb511601ed7b0bE1aE776996?embed=1&theme=dark'}
                // src={'https://dexscreener.com/blast/0xB58Fd25E1b9657B5bb511601ed7b0bE1aE776996?embed=1&theme=dark&trades=0&info=0'}
                src={'https://dexscreener.com/blast/0xB58Fd25E1b9657B5bb511601ed7b0bE1aE776996?embed=1&theme=dark&trades=0&info=0'}
                title='Blast NO'
                frameBorder={0}
                style={{
                    display: 'flex',
                    width: isMobile ? window.innerWidth + 'px' : window.innerWidth * 0.5 + 'px',
                    height: isMobile ? '12rem' : '24rem',
                    fontSize: '1.2rem',
                    fontStyle: 'bold',
                    justifyContent: 'center',
                    fontWeight: 'bold',
                    borderRadius: 12
                }}
            />
            </div>
            } */}
        </FlexContainer>
    );
}

export default memo(Mint);
