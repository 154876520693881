/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable quotes */
import React, { memo, useContext, useEffect, useState } from 'react';
// import styled from 'styled-components/macro';
import {
    NO_ADDRESS,
    YES_ADDRESS,
    ABI_NO,
    ABI_SMORE,
    ABI_ERC20,
    PINK_PRIMARY,
    PINK_SECONDARY,
    SMORE_ADDRESS,
    BLACK,
    MAYBE_ADDRESS,
    MAYBE_BLUE,
    ONE_HUNDRED_THOUSAND,
    // BLUE,
} from '../../constants';
import Image from '../Image';
import { TAN, YELLOW, PURPLE, WHITE } from '../../constants/index';
import { UserDataContext } from '../../contexts/UserDataContext';
import { FlexContainer } from '../../styled/Common';

import { useContract, useWaitForTransaction } from 'wagmi';
import { CrocEnvContext } from '../../contexts/CrocEnvContext';
import { getSigner } from '../../utils';
import { ethers } from 'ethers';
import Logo from '../Logo';
// import { Typography } from '@material-ui/core';
import { TiPlus } from 'react-icons/ti';
import { usePlayerInfo, useSmoreInfo } from '../../utils/hooks/useAPI';

function Maybe() {
    // const {
    //     crocEnv,
    //     chainData, // : { chainId },
    //     // ethMainnetUsdPrice,
    //     // provider,
    // } = useContext(CrocEnvContext);

    // amounts //
    const [amount, setAmount] = useState('');
    // const [balance, setBalance] = useState('0');
    // const [maybeBalance, setMaybeBalance] = useState('0');
    const [showApprove, setShowApprove] = useState(true);
    const { isUserConnected, userAddress } = useContext(UserDataContext);

    const account =
        userAddress?.toString() ?? '0xFd63Bf84471Bc55DD9A83fdFA293CCBD27e1F4C8';

    //  PLAYER INFO //
    const { playerInfo } = usePlayerInfo(account);
    // const playerRounds = Number(playerInfo?.rounds);
    // const playerWins = Number(playerInfo?.wins);
    const playerRewarded = isUserConnected ? Number(playerInfo?.rewarded) : 0;
    // const playerTries = Number(playerInfo?.tries)
    const playerWinRate = isUserConnected ? Number(playerInfo?.winRate) : 0;
    // const yesBalance = Number(playerInfo?.yesBalance)
    // const noBalance = Number(playerInfo?.noBalance)
    const maybeBalance = isUserConnected ? Number(playerInfo?.maybeBalance) : 0;
    const playable = isUserConnected ? Number(playerInfo?.playable) : 0;

    // console.log('playerRounds: %s', playerRounds)
    // console.log('playerWins: %s', playerWins)
    // console.log('playerRewarded: %s', playerRewarded)
    // console.log('playerWinRate: %s', playerWinRate)

    //  GAME INFO //
    const { smoreInfo } = useSmoreInfo();
    // const gameRounds = Number(smoreInfo?.totalRounds)
    // const gameWins = Number(smoreInfo?.totalWins)
    const minimumBet = Number(smoreInfo?.minimumBet);
    // const playerTries = Number(smoreInfo?.tries)
    // const gameWinRate = Number(smoreInfo?.winRate)

    // console.log('gameRounds: %s', gameRounds)
    // console.log('gameWins: %s', gameWins)
    // console.log('minimumBet: %s', minimumBet)
    // console.log('gameWinRate: %s', gameWinRate)

    const library = userAddress
        ? // @ts-ignore
          new ethers.providers.Web3Provider(window.ethereum)
        : new ethers.providers.JsonRpcProvider('https://rpc.blast.io/');
    // @ts-ignore
    const signer = getSigner(library, account); // .connectUnchecked();
    const YesContract = useContract({
        address: YES_ADDRESS,
        abi: ABI_ERC20,
        signerOrProvider: signer,
    });

    const NoContract = useContract({
        address: NO_ADDRESS,
        abi: ABI_NO,
        signerOrProvider: signer,
    });

    const SmoreContract = useContract({
        address: SMORE_ADDRESS,
        abi: ABI_SMORE,
        signerOrProvider: signer,
    });

    const MaybeContract = useContract({
        address: MAYBE_ADDRESS,
        abi: ABI_ERC20,
        signerOrProvider: signer,
    });

    // HANDLES: APPROVALS //
    const handleApprovals = async (amount: string) => {
        // const approvalAmount = (Number(amount)).toString();
        // console.log('amount: %s', amount);
        YesContract?.approve(SMORE_ADDRESS, amount);
        NoContract?.approve(SMORE_ADDRESS, amount);
        Number(amount) > 0 && setShowApprove(false);
    };

    // HANDLES: MINT //
    const handlePlay = async (amount: string) => {
        const estimatedGas = await SmoreContract?.estimateGas.trySmore(amount);
        await SmoreContract?.trySmore(amount, {
            gasLimit: estimatedGas?.mul(2),
        });
    };

    const isTiny = window.innerWidth < 424;
    const isMobile = window.innerWidth < 768;
    const mobileLR = '4px solid #ce6C87';
    const mobileTB = '4px solid #E696AC';

    return (
        <FlexContainer
            // fullHeight
            // fullWidth
            style={{
                display: 'flex',
                // flexGrow: 1,
                flexDirection: 'column',
                // width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                alignContent: 'center',
                marginTop: isMobile ? '4rem' : '6rem',
                borderTop: isMobile ? mobileTB : '12px solid #E696AC',
                borderLeft: isMobile ? mobileLR : '12px solid #ce6C87',
                borderBottom: isMobile ? mobileTB : '12px solid #E696AC',
                borderRight: isMobile ? mobileLR : '12px solid #ce6C87',
                borderRadius: 48,
                padding: isMobile ? 18 : 24,
                backgroundColor: 'rgb(246, 177, 195)',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    justifyItems: 'center',
                    textAlign: 'center',
                    backgroundColor: TAN,
                    fontSize: '1.2rem',
                    fontStyle: 'bold',
                    height: isMobile ? 32 : 64,
                    width: '100%',
                    padding: 24,
                    color: MAYBE_BLUE,
                    marginTop: 4,
                    border: '4px solid',
                    borderColor: MAYBE_BLUE,
                    borderRadius: 12,
                }}
            >
                {`S'MORE MONEY GAME`}
            </div>
            <div
                style={{
                    // display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    // backgroundColor: TAN,
                    color: PURPLE,
                    fontSize: '1.2rem',
                    fontStyle: 'bold',
                    width: isTiny ? '18rem' : isMobile ? '24rem' : '32rem',
                    // width: window.innerWidth * 0.85,
                    // height: window.innerHeight * 0.65,
                    fontWeight: 'bold',
                    maxWidth: 1200,
                }}
            >
                <head>
                    <title>{"S'more Money Game"}</title>
                    <meta
                        name='description'
                        content={"Play for S'more Money"}
                    />
                    <link rel='icon' href='/bannerImage.png' />
                </head>

                {/* INPUT DATA */}
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        // backgroundColor: TAN,
                        color: WHITE,
                        fontSize: '1.2rem',
                        fontStyle: 'bold',
                        // height: isMobile ? 32 : 64,
                        width: '100%',
                        gap: 12,
                        marginTop: isMobile ? 12 : 24,
                    }}
                >
                    <a
                        href={'/swap'}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            // justifyContent: 'space-between',
                            justifyContent: 'center',
                            backgroundColor: TAN,
                            color: YELLOW,
                            fontSize: '1.2rem',
                            fontStyle: 'bold',
                            height: isMobile ? 48 : 64,
                            width: 336,
                            border: '3px solid',
                            borderRadius: 12,
                            gap: 24,
                        }}
                    >
                        <Logo
                            address={YES_ADDRESS}
                            height={isMobile ? 24 : 48}
                            width={isMobile ? 24 : 48}
                        />
                        {'YES'}
                    </a>
                    <div
                        style={{
                            display: 'flex',
                            width: '100%',
                        }}
                    >
                        <input
                            value={amount}
                            placeholder={playable.toFixed(2).toString()}
                            onChange={(e) => setAmount(e.target.value)}
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                textAlign: 'center',
                                backgroundColor: TAN,
                                borderColor: YELLOW,
                                color: YELLOW,
                                fontSize: '1.2rem',
                                fontStyle: 'bold',
                                height: isMobile ? 48 : 64,
                                width: '100%',
                                border: '2px solid',
                                borderRadius: 12,
                            }}
                        />
                    </div>
                </div>
                {/*     PLUS ICON     */}
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'end',
                        position: 'sticky',
                        marginRight: 24,
                        padding: 2,
                        marginTop: -14,
                        marginBottom: -14,
                        // height: 2,
                    }}
                >
                    <TiPlus
                        style={{
                            border: '2px solid',
                            borderRadius: 12,
                            backgroundColor: BLACK,
                            color: MAYBE_BLUE,
                            height: 26,
                            width: 26,
                            margin: 2,
                        }}
                    />
                </div>
                {/* OUTPUT DATA */}
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        // backgroundColor: TAN,
                        color: WHITE,
                        fontSize: '1.2rem',
                        fontStyle: 'bold',
                        // height: isMobile ? 32 : 64,
                        width: '100%',
                        gap: 12,
                    }}
                >
                    <a
                        href={'/mint'}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            // justifyContent: 'space-between',
                            justifyContent: 'center',
                            backgroundColor: TAN,
                            color: PURPLE,
                            fontSize: '1.2rem',
                            fontStyle: 'bold',
                            height: isMobile ? 48 : 64,
                            width: 336,
                            border: '3px solid',
                            borderRadius: 12,
                            gap: 24,
                        }}
                    >
                        <Logo
                            address={NO_ADDRESS}
                            height={isMobile ? 24 : 48}
                            width={isMobile ? 24 : 48}
                        />
                        {'NO'}
                    </a>
                    <div
                        style={{
                            display: 'flex',
                            width: '100%',
                        }}
                    >
                        <input
                            value={amount}
                            // todo: update playable amount to ~ "below minimum of 0.1 (var)"
                            placeholder={playable.toFixed(2).toString()} // 'Enter Amount'
                            onChange={(e) => setAmount(e.target.value)}
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                textAlign: 'center',
                                backgroundColor: TAN,
                                borderColor: PURPLE,
                                color: PURPLE,
                                fontSize: '1.2rem',
                                fontStyle: 'bold',
                                height: isMobile ? 48 : 64,
                                width: '100%',
                                border: '2px solid',
                                borderRadius: 12,
                            }}
                        />
                    </div>
                </div>

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        alignContent: 'center',
                        backgroundColor: PINK_SECONDARY,
                        color: PINK_PRIMARY,
                        fontSize: '1.2rem',
                        fontStyle: 'bold',
                        width: '100%',
                        marginTop: isMobile ? 12 : 18,
                        borderRadius: 12,
                        fontWeight: 'bold',
                        border: '3px solid',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginBottom: '0.5rem',
                            fontSize: '1rem',
                        }}
                    ></div>
                    {/* {!isMobile && (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    marginBottom: '0.5rem',
                                    fontSize: '1rem',
                                }}
                            >
                                <Typography
                                    // className={''}
                                    style={{
                                        fontSize: isMobile ? 12 : 18,
                                        padding: 12,
                                        width: '100%',
                                        textAlign: 'center',
                                        fontWeight: 'bold',
                                        backgroundColor: BLACK,
                                        color: MAYBE_BLUE,
                                        border: '2px solid',
                                        borderRadius: 12,
                                    }}
                                >
                                    {`Game Win Rate: ${gameWinRate}%`}
                                </Typography>
                            </div>
                        )
                    } */}
                    <div
                        style={{
                            display: 'flex',
                            alignContent: 'center',
                            alignItems: 'center',
                            justifyContent: 'center',
                            // marginLeft: 12,
                        }}
                    >
                        <Image
                            src={'/diagram.png'}
                            height={isMobile ? 196 : 296}
                            width={isMobile ? 332 : 496}
                            className={''}
                        />
                    </div>
                </div>

                {/* APPROVE BUTTON */}
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                    }}
                >
                    {isUserConnected && Number(amount) > 0 && showApprove && (
                        <button
                            onClick={
                                () =>
                                    handleApprovals(
                                        ONE_HUNDRED_THOUSAND,
                                        // (Number(amount) * 1E18).toString(),
                                    )
                                //   : () => open({ view: 'Connect' })
                            }
                            disabled={!isUserConnected}
                            style={{
                                display: 'flex',
                                width: '100%',
                                border: '3px solid #ce6C87',
                                borderRadius: 12,
                                marginTop:
                                    isUserConnected && isMobile ? 12 : 24,
                                backgroundColor: '#15120e', // brown
                                color: PINK_PRIMARY,
                                fontSize: isMobile ? '0.85rem' : '1.2rem',
                                fontStyle: 'bold',
                                justifyContent: 'center',
                                padding: '1rem',
                                fontWeight: 'bold',
                            }}
                        >
                            {isUserConnected
                                ? `Approve Assets`
                                : 'Wallet Disconnected'}
                        </button>
                    )}
                    {/* MINT BUTTON */}
                    <button
                        onClick={() =>
                            handlePlay((Number(amount) * 1e18).toString())
                        }
                        disabled={!isUserConnected || !amount}
                        style={{
                            display: 'flex',
                            width: '100%',
                            border: '3px solid #ce6C87',
                            borderRadius: 12,
                            marginTop: isMobile ? 12 : 24,
                            backgroundColor: TAN, // brown
                            color: PINK_PRIMARY,
                            fontSize: isMobile ? '0.85rem' : '1.2rem',
                            fontStyle: 'bold',
                            justifyContent: 'center',
                            padding: '1rem',
                            fontWeight: 'bold',
                            // height: '1rem',
                        }}
                    >
                        {!amount
                            ? 'Enter Amount'
                            : Number(amount) < Number(minimumBet)
                            ? `Below Minimum (${minimumBet})`
                            : userAddress
                            ? `Pay & Play`
                            : 'Wallet Disconnected'}
                    </button>
                </div>
            </div>
            {Number(maybeBalance) > 0 && (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        border: '4px solid',
                        width: '100%',
                        fontWeight: 'bold',
                        fontSize: isMobile ? '0.85rem' : '1.2rem',
                        padding: 12,
                        borderRadius: 12,
                        backgroundColor: TAN,
                        color: MAYBE_BLUE,
                    }}
                >
                    {/* <div
                    style={{
                        display: 'flex',
                        width: '100%',
                        fontWeight: 'bold',
                        color: BLUE,
                    }}
                > */}
                    {`Won ${playerRewarded.toString()} MAYBE with a win rate of ${playerWinRate}%`}
                </div>
            )}
        </FlexContainer>
    );
}

export default memo(Maybe);
