export const GAS_DROPS_ESTIMATE_SWAP_NATIVE = 100000;
export const GAS_DROPS_ESTIMATE_SWAP_TO_FROM_DEX = 92000;
export const GAS_DROPS_ESTIMATE_SWAP_FROM_DEX = 97000;
export const GAS_DROPS_ESTIMATE_SWAP_FROM_WALLET_TO_WALLET = 110000;
export const GAS_DROPS_ESTIMATE_SWAP_FROM_WALLET_TO_DEX = 105000;
export const GAS_DROPS_ESTIMATE_LIMIT_NATIVE = 120000;
export const GAS_DROPS_ESTIMATE_LIMIT_FROM_DEX = 120000;
export const GAS_DROPS_ESTIMATE_LIMIT_FROM_WALLET = 150000;
export const GAS_DROPS_ESTIMATE_POOL = 140000;
export const GAS_DROPS_ESTIMATE_INIT_WITHOUT_POOL = 155000;
export const GAS_DROPS_ESTIMATE_INIT_WITH_POOL = 400000;
export const GAS_DROPS_ESTIMATE_LIMIT_REMOVAL = 90069;
export const GAS_DROPS_ESTIMATE_LIMIT_CLAIM = 68309;
export const GAS_DROPS_ESTIMATE_RANGE_REMOVAL = 94500;
export const GAS_DROPS_ESTIMATE_RANGE_HARVEST = 92500;
export const GAS_DROPS_ESTIMATE_REPOSITION = 260705;
export const GAS_DROPS_ESTIMATE_DEPOSIT_NATIVE = 41000;
export const GAS_DROPS_ESTIMATE_DEPOSIT_ERC20 = 93000;
export const GAS_DROPS_ESTIMATE_TRANSFER_NATIVE = 45000;
export const GAS_DROPS_ESTIMATE_TRANSFER_ERC20 = 45000;
export const GAS_DROPS_ESTIMATE_WITHDRAWAL_NATIVE = 48000;
export const GAS_DROPS_ESTIMATE_WITHDRAWAL_ERC20 = 60000;

export const NUM_WEI_IN_GWEI = 1e9;
export const NUM_GWEI_IN_ETH = 1e9;
export const NUM_GWEI_IN_WEI = 1e-9;
export const SWAP_BUFFER_MULTIPLIER_MAINNET = 2;
export const SWAP_BUFFER_MULTIPLIER_SCROLL = 1.1;
export const LIMIT_BUFFER_MULTIPLIER_MAINNET = 2;
export const LIMIT_BUFFER_MULTIPLIER_SCROLL = 1.1;
export const RANGE_BUFFER_MULTIPLIER_MAINNET = 2;
export const RANGE_BUFFER_MULTIPLIER_SCROLL = 1.1;
export const DEPOSIT_BUFFER_MULTIPLIER_MAINNET = 2;
export const DEPOSIT_BUFFER_MULTIPLIER_SCROLL = 2;

export const DEFAULT_SCROLL_GAS_PRICE_IN_GWEI = 0.4;
export const DEFAULT_MAINNET_GAS_PRICE_IN_GWEI = 69;
