/* eslint-disable quotes */
import React, {
    useEffect,
    useState,
    memo,
    useContext,
    useCallback,
} from 'react';
import { useLocation } from 'react-router-dom';
import { AnimateSharedLayout } from 'framer-motion';
import Account from './Account/Account';
// import NetworkSelector from './NetworkSelector/NetworkSelector';
// import logo from '../../../assets/images/logos/logo.png';
// import mainLogo from '../../../assets/images/logos/logo_transparent.png';
import mainLogo from '../../../assets/images/logos/logo.svg';
// import logo from '../../../assets/images/logos/logo_mark.svg';
// import mainLogo from '../../../assets/images/logos/large.svg';
import NotificationCenter from '../../../components/Global/NotificationCenter/NotificationCenter';
// import { BiGitBranch } from 'react-icons/bi';
import { APP_ENVIRONMENT } from '../../../ambient-utils/constants';
import TradeNowButton from '../../../components/Home/Landing/TradeNowButton/TradeNowButton';
import useMediaQuery from '../../../utils/hooks/useMediaQuery';
import { AppStateContext } from '../../../contexts/AppStateContext';
import { CrocEnvContext } from '../../../contexts/CrocEnvContext';
import { PoolContext } from '../../../contexts/PoolContext';
import { SidebarContext } from '../../../contexts/SidebarContext';
import { TradeTokenContext } from '../../../contexts/TradeTokenContext';

import { TradeTableContext } from '../../../contexts/TradeTableContext';
import {
    getFormattedNumber,
    chainNumToString,
    trimString,
} from '../../../ambient-utils/dataLayer';
import {
    linkGenMethodsIF,
    swapParamsIF,
    useLinkGen,
} from '../../../utils/hooks/useLinkGen';
import {
    HeaderClasses,
    LogoContainer,
    // LogoText,
    NavigationLink,
    PrimaryHeader,
    PrimaryNavigation,
    RightSide,
    TradeNowDiv,
    UnderlinedMotionDiv,
} from '../../../styled/Components/Header';
import { FlexContainer } from '../../../styled/Common';
import Button from '../../../components/Form/Button';
// import { version as appVersion } from '../../../../package.json';
import { UserDataContext } from '../../../contexts/UserDataContext';
import { useSwitchNetwork } from 'wagmi';
import { GraphDataContext } from '../../../contexts/GraphDataContext';
import { TokenBalanceContext } from '../../../contexts/TokenBalanceContext';
import { TradeDataContext } from '../../../contexts/TradeDataContext';
import { ReceiptContext } from '../../../contexts/ReceiptContext';
// import { PURPLE } from '../../../constants';

const PageHeader = function () {
    const {
        crocEnv,
        setCrocEnv,
        chainData: { chainId, poolIndex: poolId },
    } = useContext(CrocEnvContext);

    const {
        wagmiModal: { open: openWagmiModal },
    } = useContext(AppStateContext);
    const { resetTokenBalances } = useContext(TokenBalanceContext);
    const { resetUserGraphData } = useContext(GraphDataContext);

    const { poolPriceDisplay } = useContext(PoolContext);
    const { recentPools } = useContext(SidebarContext);
    const { setShowAllData } = useContext(TradeTableContext);
    const {
        baseToken: {
            setBalance: setBaseTokenBalance,
            setDexBalance: setBaseTokenDexBalance,
        },
        quoteToken: {
            setBalance: setQuoteTokenBalance,
            setDexBalance: setQuoteTokenDexBalance,
        },
    } = useContext(TradeTokenContext);
    const { userAddress, isUserConnected, disconnectUser, ensName } =
        useContext(UserDataContext);
    const { resetReceiptData } = useContext(ReceiptContext);
    const { switchNetwork } = useSwitchNetwork();

    // eslint-disable-next-line
    const [mobileNavToggle, setMobileNavToggle] = useState<boolean>(false);

    const accountAddress =
        isUserConnected && userAddress ? trimString(userAddress, 6, 6) : '';

    const clickLogout = useCallback(async () => {
        setCrocEnv(undefined);
        setBaseTokenBalance('');
        setQuoteTokenBalance('');
        setBaseTokenDexBalance('');
        setQuoteTokenDexBalance('');
        resetUserGraphData();
        resetReceiptData();
        resetTokenBalances();
        setShowAllData(true);
        disconnectUser();
    }, []);

    const accountProps = {
        accountAddress: accountAddress,
        accountAddressFull: isUserConnected && userAddress ? userAddress : '',
        ensName: ensName || '',
        isUserLoggedIn: isUserConnected,
        clickLogout: clickLogout,
    };
    const desktopScreen = useMediaQuery('(min-width: 1020px)');

    const connectWagmiButton = (
        <Button
            idForDOM='connect_wallet_button_page_header'
            title={desktopScreen ? 'Connect Wallet' : 'Connect'}
            action={openWagmiModal}
            // thin
            flat
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyItems: 'center',
                justifyContent: 'center',
                alignContent: 'center',
                textAlign: 'center',
                height: desktopScreen ? 48 : 36,
                width: desktopScreen ? 148 : 96,
            }}
        ></Button>
    );
    // ----------------------------NAVIGATION FUNCTIONALITY-------------------------------------

    const location = useLocation();

    const { tokenA, tokenB, baseToken, quoteToken, isDenomBase } =
        useContext(TradeDataContext);
    const baseSymbol = baseToken.symbol;
    const quoteSymbol = quoteToken.symbol;
    const baseAddressInRtk = baseToken.address;
    const quoteAddressInRtk = quoteToken.address;

    useEffect(() => {
        if (baseAddressInRtk && quoteAddressInRtk && crocEnv) {
            const promise = crocEnv
                .pool(baseToken.address, quoteToken.address)
                .isInit();
            Promise.resolve(promise).then((poolExists: boolean) => {
                poolExists &&
                    recentPools.add(baseToken, quoteToken, chainId, poolId);
            });
        }
    }, [baseAddressInRtk, quoteAddressInRtk, crocEnv]);

    const poolPriceDisplayWithDenom = poolPriceDisplay
        ? isDenomBase
            ? 1 / poolPriceDisplay
            : poolPriceDisplay
        : undefined;

    const truncatedPoolPrice = getFormattedNumber({
        value: poolPriceDisplayWithDenom,
    });

    useEffect(() => {
        const path = location.pathname;

        const pathNoLeadingSlash = path.slice(1);

        const isAddressEns = pathNoLeadingSlash?.endsWith('.eth');
        const isAddressHex =
            (pathNoLeadingSlash?.startsWith('0x') &&
                pathNoLeadingSlash?.length == 42) ||
            (pathNoLeadingSlash?.startsWith('account/0x') &&
                pathNoLeadingSlash?.length == 50);

        const isPathValidAddress = path && (isAddressEns || isAddressHex);

        if (pathNoLeadingSlash === 'account') {
            document.title = `Account | S'more`;
        } else if (isPathValidAddress) {
            const pathNoPrefix = pathNoLeadingSlash.replace(/account\//, '');
            const pathNoPrefixDecoded = decodeURIComponent(pathNoPrefix);
            const ensNameOrAddressTruncated = isAddressEns
                ? pathNoPrefixDecoded.length > 15
                    ? trimString(pathNoPrefixDecoded, 10, 3, '…')
                    : pathNoPrefixDecoded
                : trimString(pathNoPrefixDecoded, 6, 0, '…');
            document.title = `${ensNameOrAddressTruncated} | S'more`;
        } else if (
            location.pathname.includes('swap') ||
            location.pathname.includes('trade')
        ) {
            document.title = isDenomBase
                ? `${baseSymbol}/${quoteSymbol} ${truncatedPoolPrice} | S'more`
                : `${quoteSymbol}/${baseSymbol} ${truncatedPoolPrice} | S'more`;
        } else if (location.pathname.includes('chat')) {
            document.title = `Chat | S'more`;
        } else if (location.pathname.includes('initpool')) {
            document.title = `Pool Initialization | S'more`;
        } else if (location.pathname.includes('explore')) {
            document.title = `Explore | S'more`;
        } else if (location.pathname.includes('mint')) {
            document.title = `Mint | S'more`;
        } else if (location.pathname.includes('404')) {
            document.title = `404 | S'more`;
        } else {
            document.title = `S'more | Get S'more Money`;
        }
    }, [baseSymbol, quoteSymbol, isDenomBase, location, truncatedPoolPrice]);

    const tradeDestination = location.pathname.includes('trade/market')
        ? '/trade/market/'
        : location.pathname.includes('trade/limit')
        ? '/trade/limit/'
        : location.pathname.includes('trade/edit')
        ? '/trade/edit/'
        : '/trade/market/';

    // hooks to generate URL paths
    const linkGenSwap: linkGenMethodsIF = useLinkGen('swap');
    // const linkGenMarket: linkGenMethodsIF = useLinkGen('market');
    // const linkGenPool: linkGenMethodsIF = useLinkGen('pool');

    const swapParams: swapParamsIF = {
        chain: chainNumToString(tokenA.chainId),
        tokenA: tokenA.address,
        tokenB: tokenB.address,
    };

    interface linkDataIF {
        title: string;
        destination: string;
        shouldDisplay: boolean;
    }

    const linkData: linkDataIF[] = [
        // {
        //     title: 'Home',
        //     destination: '/',
        //     shouldDisplay: true,
        // },
        {
            title: `YES`,
            destination: '/', // linkGenSwap.getFullURL(swapParams),
            shouldDisplay: false,
        },
        // {
        //     title: `Links`,
        //     destination: '/links',
        //     shouldDisplay: true,
        // },
        // {
        //     title: `BLAST`,
        //     destination: '/bridge',
        //     shouldDisplay: false,
        // },
        {
            title: `NO`,
            destination: '/', // '/mint',
            shouldDisplay: false,
        },
        {
            title: `MAYBE`,
            destination: '/', // '/maybe',
            shouldDisplay: false,
        },
        {
            title: `EXIT`,
            destination: '/exit',
            shouldDisplay: true,
        },
        // {
        //     title: `MORE`,
        //     destination: '/more',
        //     shouldDisplay: false,
        // },
        // {
        //     title: 'Trade',
        //     destination: linkGenMarket.getFullURL(swapParams),
        //     shouldDisplay: true,
        // },
        // {
        //     title: 'Pool',
        //     destination: linkGenPool.getFullURL(swapParams),
        //     shouldDisplay: true,
        // },
        // {
        //     title: 'Explore',
        //     destination: '/explore',
        //     shouldDisplay: true,
        // },
        // {
        //     title: 'Portfolio',
        //     destination: '/account',
        //     shouldDisplay: !!isUserConnected,
        // },
    ];

    // Most of this functionality can be achieved by using the NavLink instead of Link and accessing the isActive prop on the
    // Navlink. Access to this is needed outside of the link itself for animation purposes, which is why it is being done in this way.

    function isActive(linkDestination: string, locationPathname: string) {
        if (linkDestination.includes('/trade')) {
            if (linkDestination.includes('/pool')) {
                return locationPathname.includes('/trade/pool')
                    ? HeaderClasses.active
                    : HeaderClasses.inactive;
            } else {
                return locationPathname.includes(tradeDestination)
                    ? HeaderClasses.active
                    : HeaderClasses.inactive;
            }
        } else if (linkDestination.includes('/swap')) {
            return locationPathname.includes('/swap')
                ? HeaderClasses.active
                : HeaderClasses.inactive;
        } else {
            return locationPathname === linkDestination
                ? HeaderClasses.active
                : HeaderClasses.inactive;
        }
    }

    function isUnderlined(linkDestination: string, locationPathname: string) {
        return (
            (linkDestination.includes('/trade') &&
                (linkDestination.includes('/trade/pool')
                    ? locationPathname.includes('/trade/pool')
                    : locationPathname.includes(tradeDestination))) ||
            (locationPathname.includes('/swap') &&
                linkDestination.includes('/swap')) ||
            locationPathname === linkDestination
        );
    }

    const routeDisplay = (
        <AnimateSharedLayout>
            <PrimaryNavigation
                id='primary_navigation'
                dataVisible={mobileNavToggle}
            >
                {linkData.map((link, idx) =>
                    link.shouldDisplay ? (
                        <NavigationLink
                            tabIndex={0}
                            className={isActive(
                                link.destination,
                                location.pathname,
                            )}
                            to={link.destination}
                            key={idx}
                        >
                            {link.title}

                            {isUnderlined(
                                link.destination,
                                location.pathname,
                            ) && <UnderlinedMotionDiv layoutId='underline' />}
                        </NavigationLink>
                    ) : null,
                )}
            </PrimaryNavigation>
        </AnimateSharedLayout>
    );
    // ----------------------------END OF NAVIGATION FUNCTIONALITY-------------------------------------
    const [show, handleShow] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 1300 && location.pathname === '/') {
                handleShow(true);
            } else {
                handleShow(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <PrimaryHeader
            data-testid={'page-header'}
            fixed={location.pathname === '/'}
            // style={{
            //     border: desktopScreen
            //         ? '6px dashed #e696ac'
            //         : '3px dashed #e696ac',
            //     paddingBottom: desktopScreen ? 56 : 3,
            //     borderColor: '#e696ac',
            //     borderRadius: 2,
            // }}
        >
            <div>
                <LogoContainer to='/' aria-label='Home'>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: '#A480FF',
                            // border: desktopScreen
                            //     ? '4px solid #e696ac'
                            // : '2px solid #e696ac',
                            // ? '4px solid var(--dark2)'
                            // : '2px solid var(--dark2)',
                            // borderColor: PURPLE,
                            marginTop: desktopScreen ? '0.25rem' : '0.5rem',
                            // marginLeft: desktopScreen ? '0rem' : '0.25rem',
                            borderRadius: 4,
                            width: desktopScreen ? 48 : 40,
                            marginLeft: desktopScreen ? 2 : 4,
                        }}
                    >
                        {desktopScreen ? (
                            <img
                                src={mainLogo}
                                alt='smore'
                                height={40}
                                width={40}
                            />
                        ) : (
                            <img
                                src={mainLogo}
                                alt='smore'
                                height={36}
                                width={36}
                            />
                            // <LogoText
                            //     src={logo}
                            //     height={36}
                            //     width={36}
                            //     alt='smore'
                            // />
                        )}
                    </div>
                </LogoContainer>
            </div>
            {routeDisplay}
            <RightSide>
                {show ? (
                    <TradeNowDiv justifyContent='flex-end' alignItems='center'>
                        <TradeNowButton
                            inNav
                            fieldId='trade_now_btn_in_page_header'
                        />
                    </TradeNowDiv>
                ) : (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <FlexContainer
                            alignItems='center'
                            gap={8}
                            overflow='visible'
                        >
                            {desktopScreen && (
                                <FlexContainer fontSize='body' color={'orange'}>
                                    {APP_ENVIRONMENT !== 'production' ? (
                                        <FlexContainer
                                            alignItems='center'
                                            gap={4}
                                        >
                                            {/* {`${BRANCH_NAME} - v${appVersion}`}
                                            {APP_ENVIRONMENT !== 'testnet' && (
                                                <BiGitBranch color='yellow' />
                                            )} */}
                                        </FlexContainer>
                                    ) : null}
                                </FlexContainer>
                            )}
                            {/* <NetworkSelector switchNetwork={switchNetwork} /> */}
                            {!isUserConnected && connectWagmiButton}
                            <Account {...accountProps} />
                            <NotificationCenter />
                        </FlexContainer>
                    </div>
                )}
            </RightSide>
        </PrimaryHeader>
    );
};

export default memo(PageHeader);
