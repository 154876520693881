import React from 'react';
import { FlexContainer } from '../../../../styled/Common';
import useMediaQuery from '../../../../utils/hooks/useMediaQuery';
import {
    StyledLink,
    TradeNowButtonText,
} from '../../../../styled/Components/Home';

interface propsIF {
    fieldId: string;
    inNav?: boolean;
}

export default function MintNowButton(props: propsIF) {
    const { fieldId, inNav } = props;
    const showMobileVersion = useMediaQuery('(max-width: 600px)');

    return (
        <StyledLink
            id={fieldId}
            // to={showMobileVersion ? '/trade' : '/trade/market'}
            to={'/exit'}
            // to={'https://t.me/SmoreMoneyDeFi'}
            tabIndex={0}
            aria-label='Go to exit page button'
            inNav={inNav}
        >
            {/* <a
            href={'https://t.me/SmoreMoneyDeFi'}
            target={'_blank'}
            rel='noreferrer'
            style={{
                display: 'flex',
                textAlign: 'center',
                width: '100%',
            }}
        > */}
            <FlexContainer
                fullHeight
                fullWidth
                justifyContent='center'
                alignItems='center'
                rounded
                background='dark2'
                // style={{
                //     display: 'flex',
                //     textAlign: 'center',
                //     border: '2px solid',
                //     borderColor: 'var(--accent1)',
                //     borderRadius: 12,
                //     padding: '8px',
                // }}
            >
                <TradeNowButtonText
                    fontWeight='300'
                    font='font-logo'
                    fontSize='header2'
                    color='accent1'
                    inNav={inNav}
                >
                    {'Exit Now'}
                    {/* {'Game Paused'} */}
                    {/* <br /> */}
                    {/* {'Details on Telegram'} */}
                </TradeNowButtonText>
            </FlexContainer>
            {/* </a> */}
        </StyledLink>
    );
}
