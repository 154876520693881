import { FC } from 'react';

export type ImageProps = {
    src: string;
    width: number;
    height: number;
    className: any;
    alt?: string;
};

const Image: FC<ImageProps> = ({
    src = '',
    width = 24,
    height = 24,
    ...rest
}) => {
    return (
        <div style={{ width, height }} className='overflow-hidden rounded'>
            <img
                src={src}
                width={width}
                height={height}
                placeholder='empty'
                alt={''}
                {...rest}
            />
        </div>
    );
};

export default Image;
