/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable quotes */
import React, { memo, useContext, useEffect, useState } from 'react';
// import styled from 'styled-components/macro';
// import { TbArrowBigDownLineFilled } from 'react-icons/tb';
import { PiArrowFatLinesDownFill } from 'react-icons/pi';

import {
    NO_ADDRESS,
    // YES_ADDRESS,
    ABI_NO,
    ABI_ERC20,
    PINK_PRIMARY,
    PINK_SECONDARY,
    BLACK,
    EXIT_ADDRESS,
    ABI_EXIT,
    ONE_HUNDRED_THOUSAND,
    MAYBE_ADDRESS,
    MAYBE_BLUE,
} from '../../constants';
import { TAN, YELLOW, PURPLE, WHITE } from '../../constants/index';
import { UserDataContext } from '../../contexts/UserDataContext';
import { FlexContainer } from '../../styled/Common';

import { useContract } from 'wagmi';
import { CrocEnvContext } from '../../contexts/CrocEnvContext';
import { getSigner } from '../../utils';
import { ethers } from 'ethers';
import Logo from '../Logo';
import { usePlayerInfo } from '../../utils/hooks/useAPI';

function Exit() {
    const {
        // crocEnv,
        chainData: { chainId },
        // ethMainnetUsdPrice,
        // provider,
    } = useContext(CrocEnvContext);

    // amounts //
    const [amount, setAmount] = useState('');
    // const [balance, setBalance] = useState('0');
    const [showMaybe, setShowMaybe] = useState(false);
    const { isUserConnected, userAddress } = useContext(UserDataContext);
    // const library = new ethers.providers.JsonRpcProvider(
    //     //   @ts-ignore
    //     RPC[chainId].toString(),
    // );
    const account =
        userAddress?.toString() ?? '0xFd63Bf84471Bc55DD9A83fdFA293CCBD27e1F4C8';
    const library = userAddress
        ? // @ts-ignore
          new ethers.providers.Web3Provider(window?.ethereum)
        : new ethers.providers.JsonRpcProvider('https://rpc.blast.io/');
    // const signer = library.getSigner(account).connectUnchecked();

    //  USER INFO //
    const { playerInfo } = usePlayerInfo(account);
    // const yesBalance = isUserConnected ? Number(playerInfo?.yesBalance) : 0;
    const noBalance = isUserConnected ? Number(playerInfo?.noBalance) : 0;
    const maybeBalance = isUserConnected ? Number(playerInfo?.maybeBalance) : 0;

    // @ts-ignore
    const signer = getSigner(library, account); // .connectUnchecked();

    const MaybeContract = useContract({
        address: MAYBE_ADDRESS,
        abi: ABI_ERC20,
        signerOrProvider: signer,
    });

    const NoContract = useContract({
        address: NO_ADDRESS,
        abi: ABI_NO,
        signerOrProvider: signer,
    });

    const ExitContract = useContract({
        address: EXIT_ADDRESS,
        abi: ABI_EXIT,
        signerOrProvider: signer,
    });

    // HANDLES: APPROVAL //
    const handleApprove = async (amount: string) => {
        !showMaybe
            ? NoContract?.approve(EXIT_ADDRESS, amount)
            : MaybeContract?.approve(EXIT_ADDRESS, amount);
    };

    // HANDLES: MINT //
    const handleExit = async () => {
        console.log('exiting...');
        !showMaybe ? ExitContract?.exitNo() : ExitContract?.exitMaybe();
    };

    // const titleText = `Mint (S'more) $NO`;
    const isTiny = window.innerWidth < 424;
    const isMobile = window.innerWidth < 768;
    const mobileLR = '4px solid #ce6C87';
    const mobileTB = '4px solid #E696AC';

    const toggleShow = (isNo: boolean) => {
        setShowMaybe(isNo);
    };

    return (
        <FlexContainer
            // fullHeight
            // fullWidth
            style={{
                display: 'flex',
                // flexGrow: 1,
                flexDirection: 'column',
                // width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                alignContent: 'center',
                marginTop: isMobile ? '4rem' : '6rem',
                borderTop: isMobile ? mobileTB : '12px solid #CE6C87',
                borderLeft: isMobile ? mobileLR : '12px solid #A480FF',
                borderBottom: isMobile ? mobileTB : '12px solid #CE6C87',
                borderRight: isMobile ? mobileLR : '12px solid #A480FF',
                borderRadius: 48,
                padding: isMobile ? 18 : 24,
                backgroundColor: 'rgb(246, 177, 195)',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    justifyItems: 'center',
                    textAlign: 'center',
                    backgroundColor: PINK_PRIMARY,
                    fontSize: '1.2rem',
                    fontStyle: 'bold',
                    height: isMobile ? 32 : 64,
                    width: '100%',
                    // gap: 4,
                    marginTop: 4,
                    border: '4px solid #A480FF',
                    borderRadius: 12,
                }}
            >
                <button
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        justifyItems: 'center',
                        textAlign: 'center',
                        width: '100%',
                        height: isMobile ? 32 : 64,
                        border: '2px solid #A480FF',
                        borderRadius: 8,
                        marginTop: 24,
                        marginBottom: 24,
                        backgroundColor: !showMaybe ? WHITE : PURPLE,
                        color: !showMaybe ? PURPLE : WHITE,
                        fontSize: '1.2rem',
                        fontStyle: 'bold',
                    }}
                    onClick={() => toggleShow(false)}
                >
                    {`EXIT NO`}
                </button>
                <button
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        justifyItems: 'center',
                        textAlign: 'center',
                        width: '100%',
                        height: isMobile ? 32 : 64,
                        border: '2px solid #A480FF',
                        borderRadius: 8,
                        marginTop: 24,
                        marginBottom: 24,
                        backgroundColor: showMaybe ? WHITE : PURPLE,
                        color: showMaybe ? PURPLE : WHITE,
                        fontSize: '1.2rem',
                        fontStyle: 'bold',
                    }}
                    onClick={() => toggleShow(true)}
                >
                    {`EXIT MAYBE`}
                </button>
            </div>

            {/* {!showMaybe && ( */}
            <div
                style={{
                    // display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    // backgroundColor: TAN,
                    color: PURPLE,
                    fontSize: '1.2rem',
                    fontStyle: 'bold',
                    width: isTiny ? '18rem' : isMobile ? '24rem' : '32rem',
                    // width: window.innerWidth * 0.85,
                    // height: window.innerHeight * 0.65,
                    fontWeight: 'bold',
                    maxWidth: 1200,
                }}
            >
                <head>
                    <title>{'Exit NO'}</title>
                    <meta name='description' content={'Exit $NO'} />
                    <link rel='icon' href='/bannerImage.png' />
                </head>

                {/* INPUT DATA */}

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: WHITE,
                        fontSize: '1.2rem',
                        fontStyle: 'bold',
                        width: '100%',
                        gap: 12,
                        marginTop: isMobile ? 12 : 18,
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            // justifyContent: 'space-between',
                            justifyContent: 'center',
                            backgroundColor: TAN,
                            color: showMaybe ? MAYBE_BLUE : PURPLE,
                            fontSize: '1.2rem',
                            fontStyle: 'bold',
                            height: isMobile ? 48 : 64,
                            width: 248,
                            border: '2px solid',
                            borderRadius: 12,
                            gap: 24,
                        }}
                    >
                        {showMaybe ? `MAYBE` : `NO`}
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: TAN,
                            borderColor: YELLOW,
                            color: showMaybe ? MAYBE_BLUE : PURPLE,
                            fontSize: '1.2rem',
                            fontStyle: 'bold',
                            height: isMobile ? 48 : 64,
                            width: '100%',
                            border: '2px solid',
                            borderRadius: 12,
                        }}
                    >
                        {!showMaybe
                            ? Number(noBalance).toFixed(2)
                            : Number(maybeBalance).toFixed(2) ?? 0}
                    </div>
                </div>

                {/* DOWN ARROW ICON */}
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'end',
                        position: 'sticky',
                        marginRight: 24,
                        padding: 2,
                        marginTop: -12,
                        marginBottom: -12,
                    }}
                >
                    <PiArrowFatLinesDownFill
                        style={{
                            border: '2px solid',
                            borderRadius: 12,
                            backgroundColor: BLACK,
                            color: PURPLE,
                            height: 24,
                            width: 28,
                            margin: 2,
                            // padding: 2,
                        }}
                    />
                </div>

                {/* OUTPUT DATA */}

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        // backgroundColor: TAN,
                        color: WHITE,
                        fontSize: '1.2rem',
                        fontStyle: 'bold',
                        // height: isMobile ? 32 : 64,
                        width: '100%',
                        gap: 12,
                        marginTop: isMobile ? 12 : 14,
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            // justifyContent: 'space-between',
                            justifyContent: 'center',
                            backgroundColor: TAN,
                            color: YELLOW,
                            fontSize: '1.2rem',
                            fontStyle: 'bold',
                            height: isMobile ? 48 : 64,
                            width: 248,
                            border: '2px solid',
                            borderRadius: 12,
                            gap: 24,
                        }}
                    >
                        {/* <Logo
                                address={YES_ADDRESS}
                                height={isMobile ? 24 : 36}
                                width={isMobile ? 24 : 36}
                            /> */}
                        {'YES'}
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            width: '100%',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: TAN,
                                borderColor: YELLOW,
                                color: YELLOW,
                                fontSize: '1.2rem',
                                fontStyle: 'bold',
                                height: isMobile ? 48 : 64,
                                width: '100%',
                                border: '2px solid',
                                borderRadius: 12,
                            }}
                        >
                            {!showMaybe
                                ? Number(noBalance).toFixed(2)
                                : (Number(maybeBalance) * 2.6).toFixed(2) ?? 0}
                        </div>
                    </div>
                </div>

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: PINK_SECONDARY,
                        color: WHITE,
                        fontSize: '1.2rem',
                        fontStyle: 'bold',
                        width: '100%',
                        marginTop: isMobile ? 12 : 18,
                        borderRadius: 12,
                        fontWeight: 'bold',
                        border: '3px solid #ce6C87', // pink primary
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            // padding: '12px',
                            // marginTop: '0.85rem',
                        }}
                    >
                        {/* TODO: Make Dynamic */}
                        {showMaybe ? `1 MAYBE = 2.6 YES` : `1 NO = 1 YES`}
                    </div>
                </div>

                {/* APPROVE BUTTON */}
                {isUserConnected && (
                    <button
                        onClick={() => handleApprove(ONE_HUNDRED_THOUSAND)}
                        disabled={!isUserConnected}
                        style={{
                            display: 'flex',
                            width: '100%',
                            border: '3px solid #ce6C87',
                            borderRadius: 12,
                            marginTop: isUserConnected && isMobile ? 12 : 24,
                            backgroundColor: '#15120e', // brown
                            color: PINK_PRIMARY,
                            fontSize: '1.2rem',
                            fontStyle: 'bold',
                            justifyContent: 'center',
                            padding: '1rem',
                            fontWeight: 'bold',
                        }}
                    >
                        {isUserConnected
                            ? `Approve ${showMaybe ? 'MAYBE' : 'NO'}`
                            : 'Wallet Disconnected'}
                    </button>
                )}

                {/* EXIT BUTTON */}

                <button
                    onClick={() => handleExit()}
                    disabled={!isUserConnected}
                    style={{
                        display: 'flex',
                        width: '100%',
                        border: '3px solid #ce6C87',
                        borderRadius: 12,
                        marginTop: isMobile ? 4 : 4,
                        backgroundColor: '#15120e', // brown
                        color: PINK_PRIMARY,
                        fontSize: '1.2rem',
                        fontStyle: 'bold',
                        justifyContent: 'center',
                        padding: '1rem',
                        fontWeight: 'bold',
                        // height: '1rem',
                    }}
                >
                    {userAddress ? `Exit` : 'Wallet Disconnected'}
                </button>
            </div>
        </FlexContainer>
    );
}

export default memo(Exit);
