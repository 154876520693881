import React, { CSSProperties, FC, useState } from 'react';
import { IconProps } from 'react-feather';
import Image from '../Image';
import { classNames } from '../../functions/styling';

// const BAD_SRCS: { [tokenAddress: string]: true } = {}

export type LogoProps = {
    //   srcs: string[]
    address: string;
    width: number;
    height: number;
    alt?: string;
} & IconProps;

/**
 * Renders an image by sequentially trying a list of URIs, and then eventually a fallback triangle alert
 */
const Logo: FC<LogoProps> = ({
    address,
    width = 24,
    height = 24,
    alt = '',
    className,
    ...rest
}) => {
    const [, refresh] = useState<number>(0);
    //   const src = srcs.find((src) => !BAD_SRCS[src])
    return (
        <div className='rounded'>
            <Image
                src={
                    `https://raw.githubusercontent.com/SoulSwapFinance/assets/master/blockchains/blast/assets/${address}/logo.png` ||
                    'https://raw.githubusercontent.com/SoulSwapFinance/icons/prod/token/unknown.png'
                }
                width={width}
                height={height}
                alt={alt}
                className={classNames('rounded', className)}
                {...rest}
            />
        </div>
    );
};

// export function NetworkLogo({ chainId, style = {} }: { chainId: ChainId; style?: CSSProperties }) {
//   const { iconDark } = NETWORKS_INFO[chainId]
//   const iconSrc = iconDark
//   if (!iconSrc) return null
//   return <Image
//     unoptimized
//     legacyStyles={true}
//     src={iconSrc}
//     alt="Switch Network"
//     style={style}
//   />
// }

export default Logo;
