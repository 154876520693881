/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable quotes */
import React, { memo, useContext, useEffect, useState } from 'react';
// import styled from 'styled-components/macro';
import {
    NO_ADDRESS,
    YES_ADDRESS,
    ABI_NO,
    ABI_SMORE,
    ABI_ERC20,
    PINK_PRIMARY,
    // PINK_SECONDARY,
    SMORE_ADDRESS,
    BLACK,
    // MAYBE_ADDRESS,
    MAYBE_BLUE,
    ONE_HUNDRED_THOUSAND,
    MORE_ADDRESS,
    ABI_MORE,
    MAYBE_ADDRESS,
    LIGHT_TAN,
    PALE_YELLOW,
    // BLUE,
} from '../../constants';
// import Image from '../Image';
import { TAN, YELLOW, PURPLE, WHITE } from '../../constants/index';
import { UserDataContext } from '../../contexts/UserDataContext';
import { FlexContainer } from '../../styled/Common';

import { useContract, useWaitForTransaction } from 'wagmi';
import { CrocEnvContext } from '../../contexts/CrocEnvContext';
import { getSigner } from '../../utils';
import { ethers } from 'ethers';
import Logo from '../Logo';
// import { Typography } from '@material-ui/core';
import { TiPlus } from 'react-icons/ti';
import { useStakerInfo, useStakeInfo } from '../../utils/hooks/useAPI';

function More() {
    const {
        crocEnv,
        chainData, // : { chainId },
        // ethMainnetUsdPrice,
        // provider,
    } = useContext(CrocEnvContext);

    // amounts //
    const [amount, setAmount] = useState('');
    const [showApprove, setShowApprove] = useState(true);
    const [actionText, setActionText] = useState('Deposit');
    // const [approvalText, setApprovalText] = useState('Approve MAYBE');
    const [isStake, setStake] = useState(true);
    const { isUserConnected, userAddress } = useContext(UserDataContext);

    const account =
        userAddress?.toString() ?? '0xFd63Bf84471Bc55DD9A83fdFA293CCBD27e1F4C8';

    //  PLAYER INFO //
    const { stakerInfo } = useStakerInfo(account);
    const maybeBalance = isUserConnected ? Number(stakerInfo?.maybeBalance) : 0;
    const moreBalance = isUserConnected ? Number(stakerInfo?.moreBalance) : 0;
    const rewards = isUserConnected ? Number(stakerInfo?.rewards) : 0;

    const [placeHolder, setPlaceholder] = useState(
        Number(maybeBalance).toFixed(2).toString(),
    );

    //  GAME INFO //
    const { stakeInfo } = useStakeInfo();
    const mintRate = stakeInfo?.mintRate;
    const rewardRate = stakeInfo?.rewardRate;
    // const totalShares = stakeInfo?.totalShares;

    const library = userAddress
        ? // @ts-ignore
          new ethers.providers.Web3Provider(window.ethereum)
        : new ethers.providers.JsonRpcProvider('https://rpc.blast.io/');
    // @ts-ignore
    const signer = getSigner(library, account); // .connectUnchecked();

    const MoreContract = useContract({
        address: MORE_ADDRESS,
        abi: ABI_MORE,
        signerOrProvider: signer,
    });

    const MaybeContract = useContract({
        address: MAYBE_ADDRESS,
        abi: ABI_ERC20,
        signerOrProvider: signer,
    });

    const handleState = async (isStake: boolean) => {
        setStake(isStake);
        setPlaceholder(
            isStake
                ? Number(maybeBalance).toFixed(2).toString()
                : Number(moreBalance).toFixed(2).toString(),
        );
        setActionText(isStake ? `Deposit` : `Claim`);
    };

    const handleApprove = async () => {
        isStake
            ? await MoreContract?.enter(amount)
            : await MoreContract?.exit(amount);
        Number(amount) > 0 && setShowApprove(false);
    };
    const handleAction = async (amount: string) => {
        isStake
            ? await MaybeContract?.approve(SMORE_ADDRESS, amount)
            : await MoreContract?.approve(SMORE_ADDRESS, amount);
    };

    const isTiny = window.innerWidth < 424;
    const isMobile = window.innerWidth < 768;
    const mobileLR = '6px solid #58C5f2';
    const mobileTB = '6px solid #A480FF';

    return (
        <FlexContainer
            // fullHeight
            // fullWidth
            style={{
                display: 'flex',
                // flexGrow: 1,
                flexDirection: 'column',
                // width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                alignContent: 'center',
                marginTop: isMobile ? '4rem' : '6rem',
                borderTop: isMobile ? mobileTB : '12px solid #A480FF',
                borderLeft: isMobile ? mobileLR : '12px solid #58C5f2',
                borderBottom: isMobile ? mobileTB : '12px solid #A480FF',
                borderRight: isMobile ? mobileLR : '12px solid #58C5f2',
                borderRadius: 48,
                padding: isMobile ? 18 : 24,
                // backgroundColor: 'rgb(246, 177, 195)',
                backgroundColor: TAN,
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    justifyItems: 'center',
                    textAlign: 'center',
                    backgroundColor: TAN,
                    fontSize: '1.2rem',
                    fontStyle: 'bold',
                    height: isMobile ? 32 : 64,
                    width: '100%',
                    padding: 24,
                    color: MAYBE_BLUE,
                    marginTop: 4,
                    border: '4px solid',
                    borderColor: MAYBE_BLUE,
                    borderRadius: 12,
                }}
            >
                {`STAKE FOR S'MORE YES`}
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    justifyItems: 'center',
                    textAlign: 'center',
                    backgroundColor: isStake ? PALE_YELLOW : LIGHT_TAN,
                    fontSize: '1.2rem',
                    fontStyle: 'bold',
                    height: '100%', // isMobile ? 32 : 64,
                    width: '100%',
                    // padding: 24,
                    color: MAYBE_BLUE,
                    marginTop: 4,
                    // border: '4px solid',
                    // borderColor: MAYBE_BLUE,
                    borderRadius: 12,
                    // gap: 12,
                }}
            >
                <div
                    onClick={() => handleState(true)}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        justifyItems: 'center',
                        border: '4px solid',
                        fontWeight: 'bold',
                        borderColor: isStake ? MAYBE_BLUE : LIGHT_TAN,
                        width: '100%',
                        padding: 2,
                        borderRadius: 12,
                        backgroundColor: isStake ? MAYBE_BLUE : LIGHT_TAN,
                        color: isStake ? TAN : MAYBE_BLUE,
                    }}
                >
                    {`STAKE`}
                </div>
                <div
                    onClick={() => handleState(false)}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        justifyItems: 'center',
                        border: '4px solid',
                        fontWeight: 'bold',
                        borderColor: isStake ? PALE_YELLOW : YELLOW,
                        width: '100%',
                        padding: 2,
                        borderRadius: 12,
                        backgroundColor: isStake ? PALE_YELLOW : YELLOW,
                        color: isStake ? YELLOW : TAN,
                    }}
                >
                    {`CLAIM`}
                </div>
            </div>
            <div
                style={{
                    // display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    // backgroundColor: TAN,
                    color: PURPLE,
                    fontSize: '1.2rem',
                    fontStyle: 'bold',
                    width: isTiny ? '18rem' : isMobile ? '24rem' : '32rem',
                    // width: window.innerWidth * 0.85,
                    // height: window.innerHeight * 0.65,
                    fontWeight: 'bold',
                    maxWidth: 1200,
                }}
            >
                <head>
                    <title>{"S'more $YES"}</title>
                    <meta name='description' content={"Get S'more $YES"} />
                    <link rel='icon' href='/bannerImage.png' />
                </head>

                {/* INPUT DATA */}
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        // backgroundColor: TAN,
                        color: isStake ? MAYBE_BLUE : YELLOW,
                        fontSize: '1.2rem',
                        fontStyle: 'bold',
                        // height: isMobile ? 32 : 64,
                        width: '100%',
                        gap: 12,
                        marginTop: isMobile ? 8 : 12,
                    }}
                >
                    <a
                        href={isStake ? `/maybe` : '/swap'}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            // justifyContent: 'space-between',
                            justifyContent: 'center',
                            backgroundColor: isStake ? MAYBE_BLUE : YELLOW,
                            color: BLACK,
                            fontSize: '1.2rem',
                            fontWeight: 'bold',
                            height: isMobile ? 48 : 64,
                            width: 336,
                            // border: '3px solid',
                            borderRadius: 12,
                            gap: 24,
                        }}
                    >
                        {/* TODO: MAYBE LOGO */}
                        {/* <Logo
                            address={MAYBE_ADDRESS}
                            height={isMobile ? 24 : 48}
                            width={isMobile ? 24 : 48}
                        /> */}
                        {`${isStake ? 'MAYBE' : 'YES'}`}
                    </a>
                    <div
                        style={{
                            display: 'flex',
                            width: '100%',
                        }}
                    >
                        <input
                            value={amount}
                            placeholder={placeHolder}
                            onChange={(e) => setAmount(e.target.value)}
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                textAlign: 'center',
                                backgroundColor: TAN,
                                borderColor: isStake ? MAYBE_BLUE : YELLOW,
                                color: MAYBE_BLUE,
                                fontSize: '1.2rem',
                                fontStyle: 'bold',
                                height: isMobile ? 48 : 64,
                                width: '100%',
                                border: '2px solid',
                                borderRadius: 12,
                            }}
                        />
                    </div>
                </div>

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        alignContent: 'center',
                        backgroundColor: isStake ? MAYBE_BLUE : YELLOW,
                        color: BLACK,
                        fontSize: '1.2rem',
                        fontStyle: 'bold',
                        width: '100%',
                        marginTop: isMobile ? 12 : 18,
                        // border: '2px solid',
                        borderRadius: 4,
                        fontWeight: 'bold',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: '0.8rem',
                        }}
                    ></div>
                    <div
                        style={{
                            display: 'flex',
                            alignContent: 'center',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        {isStake
                            ? `1 MAYBE = ${mintRate} MORE`
                            : `1 MORE = ${rewardRate} YES`}
                        {/* <Image
                            src={'/diagram.png'}
                            height={isMobile ? 196 : 296}
                            width={isMobile ? 332 : 496}
                            className={''}
                        /> */}
                    </div>
                </div>

                {/* APPROVE BUTTON */}
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                    }}
                >
                    {isUserConnected && Number(amount) > 0 && showApprove && (
                        <button
                            onClick={() => handleApprove()}
                            disabled={!isUserConnected}
                            style={{
                                display: 'flex',
                                width: '100%',
                                border: '1.5px solid #58C5f2',
                                borderRadius: 12,
                                marginTop:
                                    isUserConnected && isMobile ? 12 : 24,
                                backgroundColor: '#15120e', // brown
                                color: MAYBE_BLUE,
                                fontSize: isMobile ? '0.85rem' : '1.2rem',
                                fontStyle: 'bold',
                                justifyContent: 'center',
                                padding: '1rem',
                                fontWeight: 'bold',
                            }}
                        >
                            {isUserConnected
                                ? `Approve ${isStake ? 'MAYBE' : 'MORE'}`
                                : 'Wallet Disconnected'}
                        </button>
                    )}
                    {/* MINT BUTTON */}
                    <button
                        onClick={() =>
                            handleAction((Number(amount) * 1e18).toString())
                        }
                        disabled={!isUserConnected || !amount}
                        style={{
                            display: 'flex',
                            width: '100%',
                            border: '1.5px solid #58C5f2',
                            borderRadius: 12,
                            marginTop: isMobile ? 12 : 24,
                            backgroundColor: TAN, // brown
                            color: MAYBE_BLUE,
                            fontSize: isMobile ? '0.85rem' : '1.2rem',
                            fontStyle: 'bold',
                            justifyContent: 'center',
                            padding: '1rem',
                            fontWeight: 'bold',
                            // height: '1rem',
                        }}
                    >
                        {!amount
                            ? 'Enter Amount'
                            : !userAddress
                            ? 'Wallet Disconnected'
                            : actionText}
                    </button>
                </div>
            </div>
            {Number(maybeBalance) > 0 && (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        border: '4px solid',
                        width: '100%',
                        fontWeight: 'bold',
                        fontSize: isMobile ? '0.85rem' : '1.2rem',
                        padding: 12,
                        borderRadius: 12,
                        backgroundColor: TAN,
                        color: MAYBE_BLUE,
                    }}
                >
                    {/* <div
                    style={{
                        display: 'flex',
                        width: '100%',
                        fontWeight: 'bold',
                        color: BLUE,
                    }}
                > */}
                    {/* {`Won ${playerRewarded.toString()} MAYBE with a win rate of ${playerWinRate}%`} */}
                </div>
            )}
        </FlexContainer>
    );
}

export default memo(More);
