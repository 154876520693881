export const ABI_CROC_DEX = [
    {
        inputs: [
            {
                internalType: 'uint16',
                name: 'callpath',
                type: 'uint16',
            },
            {
                internalType: 'bytes',
                name: 'cmd',
                type: 'bytes',
            },
        ],
        name: 'userCmd',
        outputs: [
            {
                internalType: 'bytes',
                name: '',
                type: 'bytes',
            },
        ],
        stateMutability: 'payable',
        type: 'function',
    },
];

export const ABI_CROC_ROUTER = [
    {
        inputs: [
            {
                internalType: 'address',
                name: 'base',
                type: 'address',
            },
            {
                internalType: 'address',
                name: 'quote',
                type: 'address',
            },
            {
                internalType: 'uint256',
                name: 'poolIdx',
                type: 'uint256',
            },
            {
                internalType: 'bool',
                name: 'isBuy',
                type: 'bool',
            },
            {
                internalType: 'bool',
                name: 'inBaseQty',
                type: 'bool',
            },
            {
                internalType: 'uint128',
                name: 'qty',
                type: 'uint128',
            },
            {
                internalType: 'uint16',
                name: 'tip',
                type: 'uint16',
            },
            {
                internalType: 'uint128',
                name: 'limitPrice',
                type: 'uint128',
            },
            {
                internalType: 'uint128',
                name: 'minOut',
                type: 'uint128',
            },
            {
                internalType: 'uint8',
                name: 'reserveFlags',
                type: 'uint8',
            },
        ],
        name: 'swap',
        outputs: [
            {
                internalType: 'int128',
                name: 'baseFlow',
                type: 'int128',
            },
            {
                internalType: 'int128',
                name: 'quoteFlow',
                type: 'int128',
            },
        ],
        stateMutability: 'payable',
        type: 'function',
    },
];

export const ABI_ERC20 = [
    // allowance(owner, spender)
    {
        // constant: true,
        inputs: [
            { internalType: 'address', name: 'owner', type: 'address' },
            { internalType: 'address', name: 'spender', type: 'address' },
        ],
        name: 'allowance',
        outputs: [
            {
                name: '',
                type: 'uint256',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    // approve(_spender, _value)
    {
        inputs: [
            { internalType: 'address', name: '_spender', type: 'address' },
            { internalType: 'uint256', name: '_value', type: 'uint256' },
        ],
        name: 'approve',
        outputs: [{ internalType: 'bool', name: 'success', type: 'bool' }],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    // totalSupply()
    {
        // constant: true,
        inputs: [],
        name: 'totalSupply',
        outputs: [
            {
                name: '',
                type: 'uint256',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        // constant: true,
        inputs: [
            {
                name: '_owner',
                type: 'address',
            },
        ],
        name: 'balanceOf',
        outputs: [
            {
                name: 'balance',
                type: 'uint256',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
];

export const ABI_NO = [
    // mint(to, amount)
    {
        inputs: [
            { internalType: 'address', name: 'to', type: 'address' },
            { internalType: 'uint256', name: 'amount', type: 'uint256' },
        ],
        name: 'mint',
        outputs: [{ internalType: 'bool', name: 'success', type: 'bool' }],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    // approve(_spender, _value)
    {
        inputs: [
            { internalType: 'address', name: '_spender', type: 'address' },
            { internalType: 'uint256', name: '_value', type: 'uint256' },
        ],
        name: 'approve',
        outputs: [{ internalType: 'bool', name: 'success', type: 'bool' }],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    // allowance(owner, spender)
    {
        // constant: true,
        inputs: [
            { internalType: 'address', name: 'owner', type: 'address' },
            { internalType: 'address', name: 'spender', type: 'address' },
        ],
        name: 'allowance',
        outputs: [
            {
                name: '',
                type: 'uint256',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    // totalSupply()
    {
        // constant: true,
        inputs: [],
        name: 'totalSupply',
        outputs: [
            {
                name: '',
                type: 'uint256',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        // constant: true,
        inputs: [
            {
                name: '_owner',
                type: 'address',
            },
        ],
        name: 'balanceOf',
        outputs: [
            {
                name: 'balance',
                type: 'uint256',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
];

export const ABI_SMORE = [
    // trySmore(amount)
    {
        inputs: [{ internalType: 'uint256', name: 'amount', type: 'uint256' }],
        name: 'trySmore',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    // getPlayerInfo(account)
    {
        // constant: true,
        inputs: [
            {
                name: 'account',
                type: 'address',
            },
        ],
        name: 'getPlayerInfo',
        outputs: [
            {
                name: 'rounds',
                type: 'uint256[]',
            },
            {
                name: 'rewarded',
                type: 'uint256',
            },
            {
                name: 'wins',
                type: 'uint256',
            },
            {
                name: 'tries',
                type: 'uint256',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    // getRoundInfo(id)
    {
        // constant: true,
        inputs: [
            {
                name: 'id',
                type: 'uint256',
            },
        ],
        name: 'getRoundInfo',
        outputs: [
            {
                name: 'player',
                type: 'address',
            },
            {
                name: 'outcome',
                type: 'uint256',
            },
            {
                name: 'bet',
                type: 'uint256',
            },
            {
                name: 'timeStamp',
                type: 'uint256',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    // getGameInfo
    {
        // constant: true,
        inputs: [],
        name: 'getGameInfo',
        outputs: [
            {
                name: '_totalRounds',
                type: 'address',
            },
            {
                name: '_totalWins',
                type: 'uint256',
            },
            {
                name: '_minimumBet',
                type: 'uint256',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
];

export const ABI_MORE = [
    // enter(amount)
    {
        inputs: [{ internalType: 'uint256', name: 'amount', type: 'uint256' }],
        name: 'enter',
        stateMutability: 'nonpayable',
        type: 'function',
    },
    // exit(amount)
    {
        inputs: [{ internalType: 'uint256', name: 'amount', type: 'uint256' }],
        name: 'exit',
        stateMutability: 'nonpayable',
        type: 'function',
    },
];
export const ABI_EXIT = [
    // exitNo
    {
        inputs: [],
        name: 'exitNo',
        stateMutability: 'nonpayable',
        type: 'function',
    },
    // exitMaybe
    {
        inputs: [],
        name: 'exitMaybe',
        stateMutability: 'nonpayable',
        type: 'function',
    },
];
