import { useContext, useEffect, useState } from 'react';
import { ChainId } from '../../constants';
import { CrocEnvContext } from '../../contexts/CrocEnvContext';

type T = Record<string, string>;

const BASE_URL = 'https://api.smore.money';

export function usePlayerInfo(userAddress: string): {
    status: string;
    playerInfo: T;
} {
    const [status, setStatus] = useState<string>('idle');
    const [playerInfo, setInfo] = useState<T>({
        player: '',
        rounds: '',
        rewarded: '',
        wins: '',
        tries: '',
        winRate: '',
        yesBalance: '',
        noBalance: '',
        maybeBalance: '',
        playable: '',
    });
    useEffect(() => {
        const fetchData = async () => {
            setStatus('fetching');
            const response = await fetch(
                `${BASE_URL}/smore/players/${userAddress}`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Referrer-Policy': 'no-referrer',
                    },
                },
            );
            const json = await response.json();
            setInfo(json as T);
            setStatus('fetched');
        };
        if (status !== 'fetched') fetchData();
    }, []);

    return { status, playerInfo };
}

export function useSmoreInfo(): { status: string; smoreInfo: T } {
    const [status, setStatus] = useState<string>('idle');
    const [smoreInfo, setInfo] = useState<T>({
        totalRounds: '',
        totalWins: '',
        minimumBet: '',
        winRate: '',
    });
    useEffect(() => {
        const fetchData = async () => {
            setStatus('fetching');
            const response = await fetch(`${BASE_URL}/smore`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Referrer-Policy': 'no-referrer',
                },
            });
            const json = await response.json();
            setInfo(json as T);
            setStatus('fetched');
        };
        if (status !== 'fetched') fetchData();
    }, []);

    return { status, smoreInfo };
}

export function useStakerInfo(userAddress: string): {
    status: string;
    stakerInfo: T;
} {
    const [status, setStatus] = useState<string>('idle');
    const [stakerInfo, setInfo] = useState<T>({
        staker: userAddress,
        maybeBalance: '',
        moreBalance: '',
        rewards: '',
    });
    useEffect(() => {
        const fetchData = async () => {
            setStatus('fetching');
            const response = await fetch(
                `${BASE_URL}/more/stakers/${userAddress}`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Referrer-Policy': 'no-referrer',
                    },
                },
            );
            const json = await response.json();
            setInfo(json as T);
            setStatus('fetched');
        };
        if (status !== 'fetched') fetchData();
    }, []);

    return { status, stakerInfo };
}

export function useStakeInfo(): {
    status: string;
    stakeInfo: T;
} {
    const [status, setStatus] = useState<string>('idle');
    const [stakeInfo, setInfo] = useState<T>({
        mintRate: '0',
        rewardRate: '0',
        totalShares: '0',
    });
    useEffect(() => {
        const fetchData = async () => {
            setStatus('fetching');
            const response = await fetch(`${BASE_URL}/more`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Referrer-Policy': 'no-referrer',
                },
            });
            const json = await response.json();
            setInfo(json as T);
            setStatus('fetched');
        };
        if (status !== 'fetched') fetchData();
    }, []);

    return { status, stakeInfo };
}
